/*
1. Body en header
2. ki heading and p development pages
3. pink buttons / card case buttons
4. pagination
5. Background colours dev sections
6. Contact / CTA section  / Background colours
7. dark color card-services.
8. tags in header
9. em int wit
10. text color/UL in sections
11. testimonials
---------------------------------------------------------------------
 */

/*

1. Body en header
---------------------------------------------------------------------
 */

body.dev{
  background: rgb(8,14,64);
  background: linear-gradient(0deg, rgba(8,14,64,1) 0%, rgba(10,29,118,1) 50%, rgba(8,14,64,1) 100%);
}
body.dev .ki-header-small{
  background-image:url(../img/bg/bg-header-development-1x.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @media screen and (min-width:1920px){
    background-image:url(../img/bg/bg-header-development-2x.jpg);
  }

}


/*


2. ki heading and p development pages
---------------------------------------------------------------------
 */

body.dev section div:not(.card-case) .ki-heading:not(.uk-card-title){
  color:$yellow;
  background-image: linear-gradient($lightblue, $lightblue);
  background-position: 0 98%;
  background-repeat: no-repeat;
  background-size: 100% clamp(10px, 2vw, 10px);
  transition: background-size 0.3s;
}
body.dev section :not(.uk-card-body) > p.ki-subtitle{
  color:$text-inverse;
  font-size: 1.75rem;
}
body.dev section :not(.uk-card-body) > p{
  color:$devgrey;
}



/* 3. pink buttons
---------------------------------------------------------------------
 */
body.dev .case-nav{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  > div{
    width:100%;
    text-align: center;
    padding:15px 0;
  }

  .uk-button,
  .button-dev{

  }
  .uk-button-default{
    line-height: 1;
  }
  @media screen and (min-width: $breakpoint-l){
    flex-wrap: nowrap;
    > div{
      width:33%;
      padding:0;
    }
    > div:first-child{
      /*align-self:flex-start;*/
      text-align: left;
    }
    > div:nth-child(2){
      text-align: center;
    }
    > div:last-child{
      text-align: right;
    }
  }
}
body.dev .case-nav .uk-button-default{
  text-transform: capitalize;
  color:$pink;
  background:transparent;
  border: 2px solid transparent;
  &:hover,
  &:active,
  &:focus{
    color:$yellow;
    box-shadow: none;
  }
}
body.dev .uk-button-text,
body.dev .case-nav .uk-button-text{
  text-transform: none;
  color:$pink;
  position:relative;
  padding-right:20px;
  //transform: translateZ(0);
  box-sizing:border-box;
  -webkit-font-smoothing: subpixel-antialiased;
  span{
    z-index:1;
    position:relative;
    padding:3px 0;
    display:inline-block;
    box-sizing:border-box;
    font-size: 1rem;
  }
  &:before {
    content: none;
  }
  span:before {
    content: "";
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 100%;
    border-bottom: none;
    transition: right .3s ease-out;
    height:8px;
    background:$lightblue;
    z-index:-1;

  }
  span:hover{
    color:$yellow;
    border-bottom: none;
    padding:3px 0;
  }
  span:hover:before {
    right: 0;
    transition: right .3s ease-out;
  }
  i:first-child{
    position:absolute;
    top:9px;
    left:-14px;
    opacity:0;
    transition: left .1s ease-out;
    transition-delay: 0s;
  }
  i:last-child{
    position:absolute;
    top:9px;
    right:0;
    transition: right .1s ease-out;

  }
  &:hover{
    color:$yellow;
    padding-right:0;
    padding-left:20px;
    transition: padding-left .1s ease-out, padding-right .1s ease-out;
    //transform: translateZ(0);
    box-sizing:border-box;
    span{
      color:$yellow;
    }
    i:first-child{
      color:$yellow;
      top:9px;
      left:0;
      opacity:1;
      transition: left .1s ease-out;
      transition-delay: 0.15s;
    }
    i:last-child{
      top:9px;
      right:-14px;
      opacity:0;
      transition: right .1s ease-out;
      color:$yellow;
    }

  }
}

body.dev .card-case .uk-button-text{
  text-transform: none;
  color:$darkblue;
  position:relative;
  padding-right:20px;
  //transform: translateZ(0);
  box-sizing:border-box;
  -webkit-font-smoothing: subpixel-antialiased;
  span{
    z-index:1;
    position:relative;
    padding:3px 0;
    display:inline-block;
    box-sizing:border-box;
  }
  &:before {
    content: none;
  }
  span:before {
    content: "";
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 100%;
    border-bottom: none;
    transition: right .3s ease-out;
    height:8px;
    background:$yellow;
    z-index:-1;

  }
  span:hover{
    color:$darkblue;
    border-bottom: none;
    padding:3px 0;
  }
  span:hover:before {
    right: 0;
    transition: right .3s ease-out;
  }
  i:first-child{
    position:absolute;
    top:9px;
    left:-14px;
    opacity:0;
    transition: left .1s ease-out;
    transition-delay: 0s;
  }
  i:last-child{
    position:absolute;
    top:9px;
    right:0;
    transition: right .1s ease-out;
  }
  &:hover{
    color:$darkblue;
    padding-right:0;
    padding-left:20px;
    transition: padding-left .1s ease-out, padding-right .1s ease-out;
    //transform: translateZ(0);
    box-sizing:border-box;
    i:first-child{
      color:$darkblue;
      top:9px;
      left:0;
      opacity:1;
      transition: left .1s ease-out;
      transition-delay: 0.15s;
    }
    i:last-child{
      top:9px;
      right:-14px;
      opacity:0;
      transition: right .1s ease-out;
    }

  }
}
body.dev .case-nav .uk-button-text_alt.button-dev{
  text-transform: none;
  color:$pink;
  position:relative;
  padding-left:20px;
  //transform: translateZ(0);
  box-sizing:border-box;
  span{
    z-index:1;
    position:relative;
    padding:3px;
    display:inline-block;
    box-sizing:border-box;
    color:$pink;
  }
  &:before {
    content: none;
  }
  span:before {
    content: "";
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 100%;
    border-bottom: none;
    transition: right .3s ease-out;
    height:8px;
    background:transparent;
    z-index:-1;

  }
  span:hover{
    color:$yellow;
    border-bottom: none;
  }
  span:hover:before {
    right: 0;
    transition: right .3s ease-out;
  }
  i:first-child{
    position:absolute;
    top:9px;
    left:0;
    opacity:1;
    transition: left .1s ease-out;
    transition-delay: 0s;
    color:$pink;
  }
  i:last-child{
    position:absolute;
    top:9px;
    right:0;
    opacity:0;
    transition: right .1s ease-out;
    color:$pink;
  }
  &:hover{
    color:$yellow;
    padding-right:20px;
    padding-left:0;
    transition: padding-left .1s ease-out, padding-right .1s ease-out;
    //transform: translateZ(0);
    box-sizing:border-box;
    i:first-child{
      color:$yellow;
      top:9px;
      left:0;
      opacity:0;
      transition: left .1s ease-out;
      transition-delay: 0.15s;
    }
    i:last-child{
      top:9px;
      right:-14px;
      opacity:1;
      transition: right .1s ease-out;
    }

  }
}
/*
4. pagination
 ---------------------------------------------------------------------
 */
body.dev .uk-pagination{
  .uk-active > *{
    color:$text-inverse;
  }
  a:hover{
    color:$yellow;
  }
}
/*
5. Background colours dev sections
 ---------------------------------------------------------------------
 */

.bg-dev-01{
  background:$bg-dev-01;
}
.bg-dev-02{
  background:$bg-dev-02;
}
.bg-dev-03{
  background:$bg-dev-03;
}
.bg-dev-04{
  background:$bg-dev-04;
}

/*
6. Contact / CTA section  / Background colours
---------------------------------------------------------------------
*/
body.dev .cta-bg,
body.dev .section-cta{
  background:transparent;
  .uk-button-default{
    background:$yellow;
    color:$darkblue;
    &:hover,
    &:active,
    &:focus{
      background:$text-inverse;
    }
  }
}

/*
7. background color card-services.
---------------------------------------------------------------------
 */
body.dev .card-services{
  background:$devcardservicebg;
  color:$text-inverse;
  .uk-card-title{
    color:$text-inverse;
  }
  .uk-card-body p{
    color:$devgrey;
  }
}
/*
8. tags in header
 ---------------------------------------------------------------------
 */

body.dev .ki-header .tag-list,
body.dev .ki-header-small .tag-list
{
  li {
    color: $pink;
  }
}
/*
9. em int wit
 ---------------------------------------------------------------------
 */
body.dev em{
  color:$text-inverse;
}

/*
10. text color/UL in sections
---------------------------------------------------------------------
 */
body.dev > .uk-section ul:not(.uk-list),
body.dev > .uk-section ol:not(.uk-list),
body.dev > .uk-section a:not(.uk-button){
  color:$devgrey;
}
body.dev > .uk-section a:not(.uk-button){
  text-decoration: underline;
}
body.dev > .uk-section ul:not(.uk-list) li,
body.dev > .uk-section ol:not(.uk-list) li{
  margin-block-end: 1.25em;
  &::marker{
    color:$lightblue;
  }
}

/*
11. testimonials
---------------------------------------------------------------------
*/

body.dev .uk-section .testimonial-slider .uk-slider-items{
  li{
    margin-bottom:0 !important;
  }
  .testimonial-item{
    color:$darkgrey !important;
    background: $text-inverse !important;
    font-style: normal;
  }
  .testimonial-text p em{
    color:$darkgrey !important;
  }
  .figcaption{
    color:$mediumgrey !important;
  }
}