/*
1. generic card
2. card services
3. cta card
4. card services homepage
5. card news
6. card news homepage
 */
/*
1. generic card
-----------------------------------------------
 */
.uk-card{
	border-radius:$border-radius;
	//color:$darkblue;
	.icon{
		width:48px;
		height:48px;
	}
}
.uk-card-title,
.uk-card-default .uk-card-title{
	font-size: 1.25rem;
	font-weight:bold;
	margin:1rem 0 1.5rem 0;
	color:$darkblue;
}

.uk-card-footer {
    padding: 20px 40px 30px 40px;
}
.uk-card-media-top img{
	border-top-right-radius:$border-radius;
	border-top-left-radius:$border-radius;
}

/*
2. card services
--------------------------------------
 */
.uk-card.card-services{
	display:flex;
	flex-direction:column;
	justify-content:space-between;
	.uk-card-body{
		padding:40px 40px 0 40px;
	}
	.uk-card-footer {
		border:none;
		padding: 20px 40px 30px 40px;
	}
}
/*
3. cta card
-----------------------
 */
.cta-card{
	background:$lillalight;
	border-radius:$border-radius;
	max-width:850px;
	padding:30px;
	text-align:center;
	p{
		color:$darkgrey !important;
	}
	h5 {
		font-size: 1.5rem;
		font-weight: 700;
	}
}
/*
4. card services homepage
 */
body.homepage .uk-card.card-services{
	height:100%;
	position: relative;
	.uk-card-body {
		padding: 20px 20px 0;
	}
	.uk-card-body p{
		line-height:1.75;
	}
	.uk-card-footer{
		padding: 20px 20px 20px 20px;
	}
	/*
	a:before{
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
	}
	*/

}
/*
5. Card news
 */
.card-news.uk-card-default{
	box-shadow: 0 0 50px rgba(0,0,0,.25);

	.uk-card-body .card-date{
		font-size:0.875rem;
		line-height:1;
		margin: 0;
	}
	.uk-card-body .uk-card-title{
		font-size:1.25rem;
		margin:0;
		@media screen and (min-width: $breakpoint-l) {
			font-size:1.5rem;
		}
	}
	.uk-card-footer {
		border-top: 1px solid transparent;
	}
}
/*
6. card news homepage
*/
.uk-card-default.card-blog-home{
	display:flex;
	flex-direction: column;
	box-shadow: 0 0 0 rgba(0,0,0,0);
	margin:0 auto;
	max-width:337px;
	@media screen and (min-width:$breakpoint-s){
		max-width:100%;
	}
	.uk-card-media-top img{
		border-top-right-radius: $border-radius-large;
		border-top-left-radius: $border-radius-large;
		border-bottom-right-radius: $border-radius-large;
		border-bottom-left-radius: $border-radius-large;
		flex:0 0 0;
	}
	.uk-card-body{
		flex:1 0 0;
		padding: 20px 10px 10px 10px;
	}
	.uk-card-title{
		margin:0;
	}
	.uk-card-footer{
		border-top: 1px solid transparent;
		flex:0 0 0;
		padding: 0px 10px;
	}
}