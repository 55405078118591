.section-cta{
	h1{
		color:$yellow;
		font-weight:bold;
		margin:0;

	}
	h2{
		color:$text-inverse;
		/*text-transform: uppercase;*/
		font-weight: 300;
		margin:0 0 20px 0;
	}
	h1,h2{
		font-size: 1.875rem;
		@media screen and (min-width:$breakpoint-m){
			font-size: 2.5rem;
		}
	}
	h2+h3{
		margin-top:0.5em;
	}
	h3{
		color:$yellow;
	}
	p{
		color:$text-inverse;
	}
	.uk-button-text{
		margin:40px 0;
		@media screen and (min-width:$breakpoint-s){
			margin:0 0 0 2rem;
		}
	}
}