/*
1. off canvas accordion version - obsolete
2. off canvas nav subnav version
 */
.uk-offcanvas-bar {
  background: $light;
  color:$darkblue;
  font-size:.875rem;
  .uk-accordion{
    margin: 3rem 0 2rem 0;
  }
  .uk-accordion-title,
  .uk-nav-header{
    font-weight:700;
    text-transform: uppercase;
    font-size:.875rem;
  }
  .uk-nav-header{
    color:$darkgrey;
  }
  .uk-close{
    /*margin-right:8px;*/
  }
  .uk-close,
  ul li a,
  .uk-accordion-title,
  .uk-accordion-title:before{
    color:$mediumgrey;
    transition: all 0.5s ease;
  }
  .uk-close:hover,
  ul li a:hover,
  .uk-accordion-title:hover,
  .uk-accordion-title:hover:before,
  .uk-open .uk-accordion-title{
    color:$darkblue;
  }

  .uk-close:active,
  ul li a:active,
  .uk-accordion-title:active,
  .uk-accordion-title:active:before,
  .uk-close:focus,
  ul li a:focus,
  .uk-accordion-title:focus,
  .uk-accordion-title:focus:before{
    color:$mediumgrey;
    transition: all 0.5s ease;
  }
  .uk-accordion-content ul li a:hover{
     color:$darkblue;
  }
  .active a{
    color:$darkblue;
  }
  .uk-active > a{
    color:$darkblue;
  }
  .uk-accordion-title{
      position:relative;
      padding: 5px 0;
      &:after{
      content:'';
      position:absolute;
      height:2px;
      width:0;
      left:0;
      bottom:0;
      background:$darkblue;
      transition: width 0.5s ease;
      }
      &:hover{
        color:$darkblue;
      }
      &:hover:after{
        width:calc(100% - 30px);
        transition: width 0.5s ease;
      }
      &:before{
        background-image: none;
        content: "\f067";
        width: 1.4em;
        height: 1.4em;
        font-family: 'Font Awesome 6 Pro';
        font-weight: 100;
      }

  }
  .uk-open>.uk-accordion-title:before{
    background-image: none;
    content: "\f068";
    font-weight: 100;
  }
  .uk-active .uk-accordion-title:after{
    width:calc(100% - 30px);
  }
  .uk-active .uk-accordion-title:before,
  .uk-open>.uk-accordion-title{
    color:$darkblue;
  }
  .uk-button-default {
    border-radius: 20px;
    background: $darkblue;
    color: $light;
    border: 1px solid $darkblue;
    &:hover{
      color:$yellow;
      background: $darkblue;
      border: 1px solid $darkblue;
      box-shadow:none;
    }
  }
  .secondary-nav{
    .uk-nav li>a {
      padding: 12px 0;
      color:$linkcolor;
    }
  }

}
/*
2. off canvas nav subnav version
*/
.uk-offcanvas-bar{
  .uk-nav > li > a {
    font-size: 1rem;
    font-weight: bold;
    color:$darkblue;
  }
  .uk-nav > li > .uk-nav-sub > li > a{
    font-size: 0.95rem;
    display:inline-block;
    padding:0.2rem 0;
  }
}