.timeline{
	position:relative;
	padding:50px 0;
	background-image:url(../img/timeline/centre-line.svg);
	background-position:100% top;
	background-repeat:repeat-y;
	@media screen and (min-width: $breakpoint-m){
		background-position:center top;
	}
}
.timeline-item{
	margin-bottom:1.5em;
	@media screen and (min-width: $breakpoint-m){
		width:50%;
		margin-bottom:0em;
	}
	position:relative;
	&:after{
		position:absolute;
		content:'';
		width:2px;
		height:calc( 100% - 6px);
		top:6px;
		right:-1px;
		background-image: linear-gradient( to bottom,$yellow,$lightblue);
	}
	
}

.timeline-date{
	font-weight:bold;
	margin:0;
	padding:0;
	color:$darkgrey;
	position:relative;
	&:after{
		position:absolute;
		content:'';
		width:12px;
		height:12px;
		border-radius:6px;
		background:$yellow;
		top:6px;
		right:-6px;
	}
	&:before{
		position:absolute;
		content:'';
		height:2px;
		width:100%;
		background:$bordergrey;
		top:11px;
		left:0;
	}
	span{
		background:$light;
		position:relative;
		z-index: 100;
		padding:0 0.5em 0 0;
	}
}


.timeline-content{
	display:flex;
	align-items: stretch;
	margin-top:20px;
	> div:first-child{
		width:100px;
		order:1;
		flex-shrink: 0;
		background-size: cover;
    background-position: center;
	}
	> div:nth-child(2){
		order:2;
		padding: 0 20px;
	}
	h3{
		font-weight:bold;
		color:$darkblue;
		font-size:1.5rem;
		margin:0;
	}
	p{
		margin:0;
	}
	
}
@media screen and (min-width: $breakpoint-m){
	.timeline-item:nth-child(even){
		margin-left:50%;
		&:after{
			right:calc( 100% - 1px);
		}

		.timeline-date{
			text-align: right;
			&:after{
				top:6px;
				right:calc(100% - 6px);
			}
			&:before{
				position:absolute;
				content:'';
				height:2px;
				width:100%;
				background:$bordergrey;
				top:11px;
				left:0;
			}
			span{
				background:$light;
				position:relative;
				z-index: 100;
				padding:0 0 0 0.5em;
			}
		}

		.timeline-content{
			> div:first-child{
				width:100px;
				order:2;
				flex-shrink: 0;
				background-size: cover;
		    background-position: center;
			}
			> div:nth-child(2){
				order:1;
				padding: 0 20px;
			}
			
		}

	}
	.timeline-item{
		/*margin-top:-100px;
		margin-bottom:100px;*/
		top: -75px;
		margin-bottom: -75px;
		min-height:200px;
	}
	.timeline-item:first-child{
		margin-top:0;
		margin-bottom: 0;
		top:0;
	}
}

