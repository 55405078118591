.uk-grid{
	.inner-image img{
		display:block;
		margin:0 auto;
		@media screen and (min-width: $breakpoint-m){
			margin:0;
		}
	}
}
.uk-card img,
.uk-panel img{
	image-rendering: -webkit-optimize-contrast;
}