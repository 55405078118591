.uk-label{
	background:$lilladark;
	color:$listlinkcolor;
	font-size:.75rem;
	border-radius:9px;
	&:hover,
	&:active,
	&:focus{
		text-decoration: none;
		background:$darkblue;
		color:$text-inverse;
	}
}