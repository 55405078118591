
.freeform-form-errors {
  margin-top: 0px;
}
.freeform-button-container {
  margin-bottom: 0;
}
button {
  @extend .uk-button;
  @extend .uk-button-primary;
}

button[type=submit].freeform-processing {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
}
button[type=submit].freeform-processing:before {
  content: "";
  display: block;
  flex: 1 0 11px;
  width: 11px;
  height: 11px;
  margin-right: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: transparent transparent #fff #fff;
  border-radius: 50%;
  animation: freeform-processing .5s linear infinite;
}
@keyframes freeform-processing {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
}
.freeform-pages {
  display: flex;
  padding: 0;
  margin: 25px 0 25px 0;
  list-style: none;
  border-bottom: 2px solid #dddddd;
}
.freeform-pages li {
  color: #6c757d;
  background: transparent;
  padding: 10px 20px;
  margin: 0;
}
.freeform-pages li.active {
  color: black;
  font-weight: bold;
  background: #dddddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.freeform-row {
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
}
.freeform-row .freeform-column {
  flex: 1 0;
  padding: 10px 0;
  margin: 0 15px;
  box-sizing: border-box;
}
.freeform-row .freeform-column > .freeform-row:first-child {
  margin-top: -10px;
}
.freeform-row .freeform-column label {
  display: block;
}
.freeform-row .freeform-column .input-group-one-line {
  display: flex;
  flex-wrap: wrap;
}
.freeform-row .freeform-column .input-group-one-line label {
  padding-right: 10px;
}
.freeform-row .freeform-column .freeform-label,
.freeform-group .freeform-label {
  /*font-weight: bold;*/
}
.freeform-row .freeform-column .freeform-label.freeform-required:after {
  content: "*";
  margin-left: 5px;
  color: red;
}
.freeform-row .freeform-column .freeform-input[type=text],
.freeform-row .freeform-column .freeform-input[type=email]
{
  @extend .uk-input;
}
.freeform-row .freeform-column .freeform-input[type=checkbox] {
  //margin-right: 5px;
  @extend .uk-checkbox;
}
.freeform-row .freeform-column .freeform-input[type=radio] {
  //margin-right: 5px;
  @extend .uk-radio;
}
.freeform-row .freeform-column textarea.freeform-input {
  //margin-right: 5px;
  @extend .uk-textarea;
}
.freeform-row .freeform-column .freeform-input-only-label {
  font-weight: normal;
}
.freeform-row .freeform-column .freeform-input-only-label > .freeform-input {
}
.freeform-row .freeform-column .freeform-errors {
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
}
.freeform-row .freeform-column .freeform-errors > li {
  color: red;
}
.freeform-row .freeform-column .freeform-instructions {
  margin: 0 0 5px;
  font-size: 13px;
  color: #aba7a7;
}
.freeform-form-errors {
  padding: 15px;
  background-color: #dc3545;
  border: 1px solid #dc3545;
  color: white;
  margin: 25px 0 25px 0;
}

.freeform-form-success {
  padding: 15px;
  background-color: #198754;
  border: 1px solid #198754;
  color: white;
  margin: 25px 0 25px 0;
}
.freeform-form-errors > p {
  margin: 0;
}
.freeform-group {
  width: 100%;
  padding: 0 15px;
}

.freeform-button-container {
  margin: 25px 0 25px 0;
}