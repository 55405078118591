.list-cross{
	list-style: none;
	position:relative;
	line-height:2;
	li{
		position:relative;
		&:before{
			position:absolute;
			content:'\f00d';
			font-family: 'Font Awesome 6 Pro';
  		font-style: normal;
  		font-weight: 100;
  		font-size:inherit;
  		left:-24px;
  		top:0;
  		color:$yellow;
		}
	}
}
.section-cta{
	.list-cross{
	  margin: 0 auto;
	}
}