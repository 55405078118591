/*
footer{
  position:relative;
  padding-top: 8.854166666666668VW;
}
.footer-top,
.footer-top svg,
#footer-top
{
  position:absolute;
  top:1px;
  left:0;
  width:100%;
  height:8.854166666666668VW;
}
*/
footer{
  position:relative;

  &:after{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: $footerbg;
    //z-index: -1;
    top: 0;
    -webkit-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: skewY(-6deg);
    transform: skewY(-6deg);
  }
  .ki-heading + * {
    margin-top: 10px !important;
    display:block;
  }
  .ki-heading a{
    text-decoration: none;
    &:hover,
    &:active,
    &:focus{
      color:$lightblue;
      text-decoration: none;
      background-image: linear-gradient($darkblue,$darkblue);
    }
  }
}


.footer-content{
  background:$footerbg;
  position:relative;
  z-index:11;
}
svg#footer-top{
  /*fill:$footerbg;*/
  polygon{
    fill:$footerbg !important;
  }
}
.legal{
  position:relative;
  z-index:10;
  color:$text-inverse;
  padding:1rem;
  font-size:0.875rem;
  background:linear-gradient(
                          to right,
                          $gradientlegal1,
                          $gradientlegal2,
                          $gradientlegal3
          );
  a{
    color:$text-inverse;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus{
      color:$text-inverse;
      text-decoration: underline;
    }
  }
  &:before{
    content:'';
    position:absolute;
    width:100%;
    height:3px;
    left:0;
    top:0;
    background-image:
            linear-gradient(
                            to right,
                            $gradientyellow,
                            $gradientblue
            );
  }

}
.logo-footer{
  margin-bottom:50px;
}
/* bollekes in footer weg op mobile */
footer .legal span[style]{
  display:none;
  @media screen and (min-width:$breakpoint-s){
    display:inline;
  }
}