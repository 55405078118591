.ki-header-checkout{
  .subtotal{
    line-height:1.4;
    padding:1.25em 0;
    .subtotal--title{
      color:$light;
      font-weight: bold;
      line-height:1.2;
      margin:0.5em 0;
    }
    .subtotal--amount{
      color:$yellow;
      font-weight: bold;
      font-size: 1.75rem;
      line-height:1.2;
      padding:0;
      margin:0.5em 0;
    }
  }
}

.steps{
  width:fit-content;
  list-style-type: none;
  margin:0;
  padding:0.25em 0 1.5em 0;
  color:$light;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  /*border-bottom:2px solid rgba($light, .25);*/
  .step{

    span{
      color:$light;
      text-decoration: none;
      padding-right:2em;
      position:relative;
      &:after{
        color:$light;
        font-family: "Font Awesome 6 Pro";
        content:'\f178';
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        padding-right: 0.5em;
        margin-right: 0.2em;
      }
      &:hover,
      &:active,
      &:focus{
        color:$yellow;
      }
    }
    &.active{
      span{
        color:$yellow;
      }
    }
  }
  .step:last-child{
    span{
      padding-right:0;
    }
    span:after{
      content:'';
    }
  }
}