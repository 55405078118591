/* GRADIENT LINE WHEN STICKY */
.mainnav.uk-sticky-fixed:after{
  content:'';
  position:absolute;
  width:100%;
  height:3px;
  left:0;
  bottom:0;
  background-image:
          linear-gradient(
                          to right,
                          $gradientyellow,
                          $gradientgreen,
                          $gradientblue
          );
}

.mainnav{
  /*position: absolute;
  top:40px;
  left:0;
  width:100%;*/
  position: relative;
  color:$darkblue;
  /*off canvas toggle on transparent */
  .toggle{
  	color:$light;
  }
  li{
  		margin-right:1rem;
	  @media screen and (min-width: $breakpoint-m){
		  margin-right:0.5rem;
	  }
	  @media screen and (min-width: $breakpoint-l){
		  margin-right:1rem;
	  }
  }
  /* LINKS WHEN TRANSPARENT NAVBAR*/
  .uk-navbar-nav > li > a{
	    color:$light;
	    font-weight: 700;
	    position:relative;
	    font-size: 1rem;
	    &:after{
	      content:'';
	      position:absolute;
	      height:2px;
	      width:0;
	      left:0;
	      bottom:20px;
	      background:$light;
	      transition: width 0.5s ease;
	    }
	    &:hover,
	    &:active,
	    &:focus{
	      color:$light;
	    }
	    &:hover:after{
	      width:100%;
	      transition: width 0.5s ease;
	    }
	}
	.uk-navbar-nav > li.uk-active > a:after{
 		width:100%;
	}
	.uk-navbar-item  a.uk-button.uk-button-secondary:not(.uk-button-cart){
		font-size: 0.75rem;
		padding: 0 .75em;
		@media screen and (min-width: $breakpoint-l){
			font-size: .875rem;
			padding: 0 30px;
		}
	}
	.uk-navbar-item{
		padding: 0 5px;
		&.uk-logo{
			padding-right:1em;
		}
		@media screen and (min-width: $breakpoint-l) {
			padding: 0 15px;
		}
	}
  /* MAIN STICKY NAV */
  .uk-navbar-sticky{
  	.toggle{
  		color:$darkblue;
  	}
	  .uk-navbar-nav > li > a{
	    color:$darkblue;
	    font-weight: 700;
	    position:relative;
	    &:after{
	      content:'';
	      position:absolute;
	      height:2px;
	      width:0;
	      left:0;
	      bottom:20px;
	      background:$darkblue;
	      transition: width 0.5s ease;
	    }
	    &:hover,
	    &:active,
	    &:focus{
	      color:$darkblue;
	    }
	    &:hover:after{
	      width:100%;
	      transition: width 0.5s ease;
	    }
	  }
	  .uk-navbar-nav > li.uk-active > a:after{
	 		width:100% !important;
		}
  }
}

.uk-navbar-sticky{
  background:$light;
  .uk-navbar-nav a{
		color:$darkblue;
  }
  .uk-navbar-dropdown-nav a{
  	color:$mediumgrey;
  }
}


/* LOGOS */
.logo-color{
	display:none;
}
.uk-navbar-sticky .logo-color{
	display:block;
}
.uk-navbar-sticky .logo-white{
	display:none;
}
.uk-logo{
	max-width: 150px;

	@media screen and (min-width: $breakpoint-m){
		max-width: 200px;
	}
	@media screen and (min-width: $breakpoint-l){
		max-width: 270px;
	}
}