/**
  HTMX
 */

.htmx-request {
  opacity: 0.7;
  cursor: wait;
}

.htmx-request * {
  pointer-events: none;
}


.scroll-margin-fix {
  scroll-margin-top: 110px;
}

/** LINK */

.uk-link {
  color: #0f27ae;
}
.uk-link:hover {
  color: #0f27ae;
  text-decoration: underline;
}

/** LINK LIST */

ul.uk-link-list {

}

ul.uk-link-list.uk-link-list-vertical li {
  margin: 0 0 5px 0 !important;
}

ul.uk-link-list.uk-link-list-horizontal li {
  margin: 0 5px 5px 0 !important;
  display: inline-block;
}

/** MORE LISTS **/

.list-chevron {
  list-style-type: none;
  position:relative;
  line-height:2;
  li{
    position:relative;
    &:before{
      position:absolute;
      content:"\f00c";
      font-family: 'Font Awesome 6 Pro';
      font-style: normal;
      font-weight: 900;
      font-size:inherit;
      left:-24px;
      top:0;
      color:inherit;
    }
  }
}
.list-chevron--blue li:before{
   color:$darkblue;
}

/** Domain name IDN table **/

table.table-idn-chars {
  border-collapse: collapse;
  width: auto;
  margin: 20px auto;
}
table.table-idn-chars > tbody > tr > td {
  border: 1px solid #ddd !important;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  padding: 0 !important;
}

/* generic price */

span.ki-price-old {
  text-decoration: line-through;
  color: #a0a0a0;
}

span.ki-price {
  color: #0f27ae;
  font-weight: bold;
}

span.ki-price-promo {
  color: #0f27ae;
  font-weight: bold;
  display: inline-block;
  position: relative;
  z-index: 0;
}

span.ki-price-promo::before {
  content: " ";
  position: absolute;
  display: block;
  left: 0;
  bottom: 2px;
  width: 100%;
  height: 5px;
  background: #ffce00;
  z-index: -1;
}

span.ki-price-text {
  font-size: 84%;
  color: #444;
}

.uk-label.uk-label-sales {
  background: #ffce00;
  color: white;
}



.placeholder-block {
  width: 100%;
  height: 400px;
  background: rgba(0,0,0,0.1);
  position: relative;
}
.placeholder-block-text {
  font-size: 80%;
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px;
  color: white;
  background: rgba(0,0,0,0.7);
}
.placeholder-block-text * {
  color: white;
}
.placeholder-block svg {
  width: 100%;
  height: 100%;
}
.placeholder-block svg line {
  stroke: #999999;
  stroke-width: 1;
}



table td.promo {
  position: relative;
  width: 15px;
}
table span.badge-promo {
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  width: 26px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: #ffce00;
  color: #0f27ae;
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: uppercase;
  display: block;
  text-align: center;
  line-height: 26px;
}

/**
 * Michiel: dit heb ik er in gezet om de priceboxes in headers visible te maken.
 * denk dat deze overflow hidden eigenlijk niet nodig was.
 */

.ki-header--skewed {
  overflow: visible !important;;
}


hr.large {
  border-width: 2px;
}
hr.yellow {
  border-color: #ffce00;
}

.list-check ul {
  list-style-type: none;
  position:relative;
  line-height:2;
  li{
    position:relative;
    &:before{
      position:absolute;
      content:'\f00c';
      font-family: 'Font Awesome 6 Pro';
      font-style: normal;
      font-weight: 900;
      font-size:inherit;
      left:-24px;
      top:0;
      color:$darkblue;
    }
  }
}




ul.uk-list-steps {
  margin: 0;
  padding: 0;
}
ul.uk-list-steps li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  color: white;
  font-size: 90%;
}
ul.uk-list-steps li:not(:last-child) {
  margin-right: 20px;
}
ul.uk-list-steps li:not(:last-child):after {
  content: '>';
  margin-left: 20px;
}
ul.uk-list-steps li.active {
  font-weight: bold;
  color: #ffce00;
}


div.selectable-list {
  border: 1px solid silver;
  max-height: 50vh;
  overflow-y: scroll;
  margin-top: 20px;
}
div.selectable-list > div.selectable-list-item {
  padding: 10px;
  border-bottom: 1px solid silver;
}
div.selectable-list > div.selectable-list-item:last-child {
  border-bottom: 0;
}
div.selectable-list > div.selectable-list-item.selected {
  background: #eaecfe;
}



.uk-label.uk-label-discount {
  background: #ffce00;
  color: #0f27ae;
  font-size: 0.875rem;
}


.price-number {
  white-space: nowrap;
}
.price-old {
  white-space: nowrap;
}
.price-duration {
  white-space: nowrap;
}

.price-old{
  text-decoration:none;
  position:relative;
  color: #999;
}
.price-old::before {
  top: 50%; /*tweak this to adjust the vertical position if it's off a bit due to your font family */
  background:#333; /*this is the color of the line*/
  opacity:.7;
  content: '';
  width: 110%;
  position: absolute;
  height:.1em;
  border-radius:.25em;
  left: -5%;
  white-space:nowrap;
  display: block;
  transform: rotate(-15deg);
}
.price-old.straight::before {transform: rotate(0deg);left:-1%;width:102%;}



div.child-animation-float > div > * {
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.2s;
  transition-property: transform;
  transition-timing-function: ease-out;
}
div.child-animation-float > div > *:hover,
div.child-animation-float > div > *:focus,
div.child-animation-float > div > *:active {
  transform: translateY(-10px);
}

div.child-animation-sink > div > * {
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.2s;
  transition-property: transform;
  transition-timing-function: ease-out;
}
div.child-animation-sink > div > *:hover,
div.child-animation-sink > div > *:focus,
div.child-animation-sink > div > *:active {
  transform: translateY(+10px);
}

div.child-animation-grow > div > * {
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  transition-property: transform;
}
div.child-animation-grow > div > *:hover,
div.child-animation-grow > div > *:focus,
div.child-animation-grow > div > *:active {
  transform: scale(1.05);
}

div.child-animation-shrink > div > * {
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  transition-property: transform;
}
div.child-animation-shrink > div > *:hover,
div.child-animation-shrink > div > *:focus,
div.child-animation-shrink > div > *:active {
  transform: scale(0.95);
}


input[readonly] {
  background: #ddd !important;
  color: #555 !important;
}