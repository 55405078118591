/*
1. Regular styling card-case
2. last card in slider
3. dotnav
4. slider-devcases-nav
 */
/*
1. Regular styling
--------------------------------------------
 */
.card-case.uk-card-default .uk-card-footer {
  border-top: 1px solid transparent;
}
.card-case,
.uk-slider .card-case{
  max-width:calc(100vw - 30px) !important;
  display: flex;
  flex-direction: column;
  .uk-card-body{
    padding: 20px 20px;
    flex:1 0 0;
  }
  .uk-card-footer{
    padding: 10px 20px 20px 20px;
  }
  h3,
  .h3,
  .uk-card-title{
    margin: 0 0 0.5rem 0;
    font-size:1.25rem;

  }
  p{
    font-size:1rem;
    margin:0 !important;
    line-height:1.5;
  }
  .uk-label{
    background:$darkblue;
    color:$text-inverse;
    border-radius:10px;
    text-transform: lowercase;
    font-size:0.825rem;
    font-weight: 600;
    padding:0.25rem 1rem;
    margin: 0 0 0.5rem 0;
  }
  @media screen and (min-width: $breakpoint-s){
    max-width:580px !important;
  }
  @media (min-width: $breakpoint-l){

    .uk-card-body{
      padding: 30px 30px 20px 30px;
    }
    .uk-card-footer{
      padding: 10px 30px 30px 30px;
    }
    h3,
    .h3,
    .uk-card-title{
      margin: 0 0 0.5rem 0;
      font-size:1.25rem;

    }
    p{
      font-size:1rem;
      margin:0 !important;
    }
  }
}
/*
2. last card in slider
----------------------------------------------
 */
.slider-devcases.uk-slider,
body.dev .uk-slider{
  ul > li:last-child .card-case{
    background:url(../img/cases/bg-other-cases.png);
    background-size: cover;
    background-position: center center;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    .uk-card-body{
      flex: 1 0 0;
      .uk-card-title{

      }
    }
    .uk-card-footer{
      .card-case--labels.uk-margin{
        margin:0;
        height:55px;
      }
    }
  }
}
/*
3. dotnav
----------------------------------------------
 */
.slider-devcases.uk-slider .uk-slider-nav,
body.dev .uk-slider .uk-slider-nav{
  > * > *{
    border:1px solid $text-inverse;
  }
  > .uk-active > *{
    background-color:$yellow;
  }
}
/*
4. slider-devcases-nav
------------------------------------------------------
 */
.slider-devcases-nav,
body.dev .uk-slider .uk-slider-nav{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > *{
    margin:0 0.5rem;
  }
  .uk-button-text{
    color:$yellow;
    &:hover{
      color:$text-inverse;
    }
    span:hover {
      color:$text-inverse;
    }
    span:before {
      background:$lightblue;
    }
    &:hover i:first-child,
    &:hover i:last-child{
      color:$text-inverse;
    }
  }
}