
.b-named-result {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1Px solid #e5e5e5;
  margin-top: -1px;
}

.b-named-result.odd {
  background: #ffffff;
  transition: background-color 0.75s ease;
}
.b-named-result.even {
  background: #f8f8f8;
  transition: background-color 0.75s ease;
}
.b-named-result:hover {
  background: #e1e6fa;
  transition: background-color 0.75s ease;
}

.b-named-result .peter-padding {
  padding: 15px 30px 0px 30px;
}
.b-named-result:last-child {
  /*padding-bottom: 15px;*/
}
.b-named-result .start-price-small {
  margin-top: 5px !important;
}
.b-named-result .start-price-small * {
  font-size: 100%;
  margin-top: 5px !important;
}

.start-price-small {
  margin-top: 5px !important;
} 
.start-price-small * {
  font-size: 100%;
  margin-top: 5px !important;
}
/* keep button text on 1 line */
.b-named-result .uk-button{
  width: max-content;
}