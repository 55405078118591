.switcher-nav{
	list-style-type: none;
	margin:0 20px;
	padding:0;
	display:flex;
	justify-content: flex-start;
	align-items: flex-start;
	/*min-height:24px;*/
	flex-wrap: wrap;
	flex-direction: column;
	@media screen and (min-width:$breakpoint-m){
		justify-content: space-evenly;
		align-items: center;
		flex-direction: row;
	}
	> li > a{
		text-transform: uppercase;
		font-weight: bold;
		color:$linkcolor;
		position:relative;
		transition:all 1s ease-in;
		padding:15px 5px;
		@media screen and (min-width:$breakpoint-l){
			padding:15px 20px;
		}
		display:block;
		img,svg{
			margin-right:0.5em;
		}
		&:hover{
			text-decoration: none;
			color:$darkblue;
			transition:all 0.5s ease-out;
		}
		span{
			position:relative;
		}
		span:after{
			position:absolute;
			content:'';
			width:0;
			height:2px;
			left:0;
			bottom:-10px;
			background:$darkblue;
			transition:all 1s ease-in;
		}
		&:hover span:after{
			width:100%;
			transition:all 0.5s ease-out;
		}
	}
	.uk-active a{
		color:$darkblue;
		span:after{
			position:absolute;
			content:'';
			width:100%;
			height:2px;
			left:0;
			bottom:-10px;
			background:$darkblue;
		}
	}
}
.switcher-card{
	margin:10px 0;
	padding:20px;
	@media screen and (min-width: $breakpoint-m){
		padding:40px;
		margin:30px 0;
	}
}
.switcher{}
.switcher-item{
	color:$darkgrey;
	h3{
		font-weight:bold;
		color:$darkblue;
	}
	h4{
		font-weight:normal;
		color:$darkblue;
	}
	p,ul,ol{
		color:$darkgrey;
	}
	a{
		color:$linkcolor;
		text-decoration: underline;
		&:hover{
			text-decoration: none;
		}
	}
}