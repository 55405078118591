/*
1. regular styling price
2. PRICE BOX HEIGHTS WITH FLEXBOX
3. CARD PROMO HEIGHTS WITH FLEXBOX 
4. CARD PRICE BOX SSL DETAIL - WITH LOGO
5. CARD EXTENSION  
*/

.price-from{
	font-size:0.875rem;
	color:$darkgrey;
}
.price-number{
	font-size: 1.875rem;
	font-size: clamp(1.5rem, calc(1.8rem + 1vw) , 2rem);
	font-weight: bold;
	color:$darkblue;
	margin: 0 5px;
  padding: 0 4px;
	@media screen and (min-width:$breakpoint-l){
		font-size: 2.25rem;
		/*@supports ( font-size:clamp() ){
			font-size:clamp(1.5rem, calc(-0.875rem + 8.333vw) , 2.5vw);
		}*/
		font-size:clamp(1.25rem, calc(1.5rem + 1vw) , 2rem);
	}
}
.price-old .price-number{
	font-weight:normal;
	font-size:0.875rem;
	color:$darkgrey;
	text-decoration: line-through;
	padding:0 4px 0 3px;
	margin: 0 5px 0 0;
}
.price-promo .price-number{
	position:relative;
	z-index:10;
	margin: 0 5px;
	padding:0 4px;
	//font-size:1.5rem;
	&:before{
		content:'';
		position:absolute;
		width:100%;
		height:8px;
		left:0;
		bottom:2px;
		background:$yellow;
		z-index:-1;
	}
}

.price-duration{
	font-size:0.875rem;
	color:$darkgrey;
}
.card-pricebox{
	color:$darkgrey;
	height:100%;
	.card-pricebox-header{
		padding:20px 10px;
		text-align: center;
		@media screen and (min-width:$breakpoint-l){
			padding:50px 10px;
		}
	}
	.card-pricebox-title{
		font-size:1.5rem;
		font-size:clamp(1.25rem, 1.5rem , 1.75rem);
		margin-bottom:0;
	}
	.card-pricebox-price{
		background:$lillalight;
		padding:20px 0;
		text-align: center;
	}
	.price-number{
		//font-size:1.5rem;
		//padding:0 0.25em;
		//@media screen and (min-width:$breakpoint-l){
			//font-size:2.25rem;
		//}
		
		margin: 0 2px 0 4px;
	}
	.card-pricebox-desc{
		font-weight: 100;
		font-size:1.125rem;
		line-height:1.8;
	}
	.card-pricebox-body{
		position:relative;
		padding: 20px;
		@media screen and (min-width:$breakpoint-l){
			padding: 30px;
		}
		ul{
			list-style-type: none;
			padding:0;
			margin: 20px 0 0 24px;
		}
		li{
			line-height: 1.4;
			margin-bottom: 1em;
		}
		hr{
			width:100%;
			border-color:$bordergrey;
		}
		hr.large{
			border-width:2px;
			margin-bottom:19px;
		}
		hr.yellow{
			border-color:$yellow;
		}
		hr:last-child{
			position: absolute;
			bottom:0;
		}
	}
	.card-pricebox-footer{
		text-align: center;
		//border-top:1px solid $bordergrey;
		border-top:none !important;
		padding: 20px 0px 20px 0px;
		//margin:0 20px;
		margin:0 auto;
		@media screen and (min-width:$breakpoint-l){
			padding: 30px 0;
			//margin:0 30px;
			margin:0 auto;
			text-align: center;
		}
		.price .price-number{
			font-size:0.875rem;
			color:$darkgrey;
			font-weight:normal;
		}
	}
}
/* PRICE BOX HEIGHTS WITH FLEXBOX */
.card-pricebox{
	//height:100%;
	display:flex;
	flex-direction: column;
	justify-content: space-between;
	.card-pricebox-header{flex-grow: 0}
	.card-pricebox-title{flex-grow: 0}
	.card-pricebox-price{flex-grow: 0}
	.card-pricebox-desc{flex-grow: 1}
	.card-pricebox-body{flex-grow: 1}
	.card-pricebox-footer{
		flex-grow: 0;
		min-width: max-content;
	}

}
/* CARD PROMO  */
.card-promo{
	height:100%;
	.card-pricebox-header{

		text-align: center;
		border-bottom:none;

		padding:10px 20px 0 20px;

		@media screen and (min-width:$breakpoint-l){
			padding:20px 30px 0 30px;
		}
	}
	.card-pricebox-title{
		font-size:1rem;
		font-size:clamp(1rem, 1.25rem , 1.5rem);

		margin-bottom:0;
	}
	.card-pricebox-body{
		padding: 20px 20px 0 20px;
		ul{
			margin-left: 24px;
		}

	}
	.card-pricebox-price{
		background:$light;
		padding:20px 0px 20px 0;
		text-align: center;
	}
	.card-pricebox-footer{
		text-align: center;
		border-top:1px solid $bordergrey;
		padding: 20px 0px;
		margin:0 20px;
	}
}

/* CARD PROMO HEIGHTS WITH FLEXBOX */
.card-promo{
	display:flex;
	flex-direction: column;
	justify-content: space-between;
	.card-pricebox-header{flex-grow: 0}
	.card-pricebox-title{flex-grow: 0}
	.card-pricebox-body{flex-grow: 1}
	.card-pricebox-price{flex-grow: 0}
	.card-pricebox-footer{flex-grow: 0}


}


/* CARD PRICE BOX SSL DETAIL - WITH LOGO */

.card-pricebox.card-logo{
	box-shadow:none;
	border:1px solid $bordergrey;
	min-width:280px;
	max-width: 400px;
	margin:0 auto;
	display:block;
	height:auto;
	.logo{
		max-height:40px;
	}
	.card-pricebox-header {
		padding: 20px 10px 20px 10px;
		border-bottom: none;
	}
	/*@media screen and (min-width: 1200px){
		.card-pricebox-header {
		    padding: 20px 10px;
		}
	}*/
	.card-pricebox-body{
		padding:5px 20px 5px 20px;
		h3{
			font-size:1.25rem;
			font-weight: bold;
			margin:0 0 0.5em 0;
		}
		.desc{
			font-size:1rem;
			font-weight: bold;
			margin:0 0 0.25em 0;
			line-height: 1.2;
		}
		p{
			font-size:.875rem;
			line-height: 1.2;
		}
		hr:last-child{
			position:relative;
		}
	}
	.card-pricebox-price {
    background: transparent;
    padding: 0;
    text-align: left;
	}
	.card-pricebox-footer {
    border-top: 1px solid transparent;
    padding: 20px 0px 20px 0px;
	}
}

/* CARD EXTENTION */
.card-pricebox.card-extension{
	.card-pricebox-header {
    padding: 20px 10px 20px 10px;
    border-bottom: none;
    flex-grow: 0;
	}
	.uk-card-title {
    margin: 0;
	}
	.card-pricebox-body {
    padding: 5px 20px 5px 20px;
    flex-grow: 1;
	}
	.card-pricebox-body hr:last-child {
    position: relative;
	}
	.card-pricebox-price{
		background: transparent;
    padding: 0;
    text-align: left;
  }
	.card-pricebox-footer {
    border-top: 1px solid transparent;
    padding: 20px 0px 20px 0px;
    flex-grow: 0;
	}
}