.ext-item{
	border:1px solid $bordergrey;
	//background:$light;
	background: rgb(255,255,255);
	background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(247,249,255,1) 100%);
	border-radius:16px;
	display:flex;
	justify-content: space-between;
	align-items: center;
	margin:0 auto;
	padding:0 20px;
	height:100px;
	box-sizing:border-box;
	transition:box-shadow 1s ease-in, border 1s ease-in;
	margin-bottom:1.5em;
	@media screen and (min-width:$breakpoint-s){
		width: 350px
	}
	&:hover{
		text-decoration: none;
		border:1px solid $darkblue;
		box-shadow:$boxshadow-diffused;
		transition:box-shadow 0.25s ease-out, border 0.5s ease-in;
	}
}
.ext-title{
	color:$darkgrey;
	text-transform: uppercase;
	font-weight: bold;
	font-size:2.25rem;
}
/*.ext-price{
	color:$darkgrey;
	sup{
		font-weight:bold;
		color:$darkblue;
	}
	sup,sub{
		margin:0 0.25em;
	}
	span{
		font-weight:bold;
		color:$darkblue;
		font-size:1.5rem;
	}

}*/


/* V2 extension link */
.ext-item-v2{
	min-height:116px;
	width:100%;
	justify-content:space-between;
	.ext-title{
		color:$darkgrey;
		text-transform: none;
		font-weight: bold;
		font-size:1.5rem;
		font-size: clamp(1.75rem,1.5vw,2rem);
		@media screen and (min-width:$breakpoint-m) and (max-width:$breakpoint-l){
			font-size:1rem;
			font-size: clamp(1rem,1.5vw,1.25rem);
		}
		@media screen and (min-width:$breakpoint-l){
			font-size:1.5rem;
			font-size: clamp(1.25rem,1.5vw,1.5rem);
		}
	}
	.ext-title,
	.ext-price{
		//height: 75px;
		//align-self: flex-end;
	}
	.ext-title{
		flex-basis: 0;
		flex-grow: 0;
	}
	.ext-price{
		flex-basis:1;
		flex-grow: 0;
	}
	.price-number{
		font-size:1.5rem;
		font-size: clamp(1.75rem,1.5vw,2rem);
		@media screen and (min-width:$breakpoint-m) and (max-width:$breakpoint-l){
			font-size:1rem;
			font-size: clamp(1rem,1.5vw,1.25rem);
		}
		@media screen and (min-width:$breakpoint-l){
			font-size:1.5rem;
			font-size: clamp(1.25rem,1.5vw,1.5rem);
		}
	}
	.price-old .price-number {
		font-size:0.875rem;
	}
	.price-from,
	.price-duration{
		font-size:0.75rem;
	}
	/*
	.price-from{
		display:inline;
		@media screen and (min-width:$breakpoint-s) and (max-width:$breakpoint-l){
			display:block;
		}
	}*/

}

/* 3 coloured panels for extensions in a list */

.ext-card--list{
	list-style-type: none;
	a{
		> div{
			transition:all 1s ease-out;
		}
		&:hover{
			text-decoration:none;
			
			> div{
				transform:scale(1.15);
				transition:all 0.25s ease-in;
				box-shadow:$boxshadow-diffused;
			}
		}
	}	
	.ext-card--item{
		border-radius:$border-radius-large;
		padding:30px 20px;
		width:250px;
		height:135px;
		box-sizing:border-box;
		display: flex;
		//justify-content: center;
		align-items:center;
		line-height: 1.2;

	}
	.price-number{
		font-size:1.5rem;
	}
	.ext-title{
		text-transform: none;
		display:block;
	}
	li:nth-child(1){
		.ext-card--item{
			background:$lightbg1;
			border:1px solid $lightbg1;
			background-image:url(../img/svg/bg-extension-blue.svg);
			background-repeat:no-repeat;
			background-size:130px 130px;
			background-position: 155px -20px;
		}
	}
	li:nth-child(2n){
		.ext-card--item{
			background:$light;
			border:1px solid $bordergrey;
			background-image:url(../img/svg/bg-extension-blue.svg);
			background-repeat:no-repeat;
			background-size:130px 130px;
			background-position: 155px -20px;
		}
		.ext-title{
			color:$yellow;
		}
	}
	li:nth-child(3){
		.ext-card--item{
			background:$darkblue;
			border:1px solid $darkblue;
			background-image:url(../img/svg/bg-extension-white.svg);
			background-repeat:no-repeat;
			background-size:130px 130px;
			background-position: 155px -20px;;
		}
		.ext-title{
				color:$lightblue;
		}
		.price-from,
		.price-number,
		.price-duration,
		.ki-price-text,
		.ki-price,
		.ki-price-text{
			color:$light;
		}
	}
}

/* GRID LAY OUT TEST */
.ext-card--list{
	max-width:445px;
	max-height:360px;
	display: grid;
  grid-template-columns: repeat(3, 1fr);
  //grid-auto-rows:repeat(5, 1fr);
  //grid-template-rows: repeat(4, 1fr);
  grid-template-rows: 108px 27px 92px 15px 118px;
  li:nth-child(1){
  	grid-column-start:1;
  	grid-column-end:3;
  	grid-row-start:1;
  	grid-row-end:3;
  	z-index: 100;
  }
 	li:nth-child(2){
	 	grid-column-start:2;
	 	grid-column-end:4;
	 	grid-row-start:2;
	 	grid-row-end:4;
	 	justify-self: end;
	 	z-index: 90;
 	}
 	li:nth-child(3){
 		grid-column-start:2;
 		grid-column-end:3;
 		grid-row-start:4;
	 	grid-row-end:5;
	 	z-index: 80;
 	}
}

/* section domain search extention */
.section-extention{
	h2{
		font-weight: bold;
		color:$light;
		font-size:2.5rem;
		font-size: clamp(2rem, 2.5vw ,3rem);
		text-align: center;
		span{
			color:$yellow;
		}
	}
	p{
		color:$light;
	}
	.uk-button-text{
		margin-left:2em;
	}
}