/*
1. list check
2. uk-list
 */
.list-check{
	list-style-type: none;
	position:relative;
	line-height:2;
	li{
		position:relative;
		&:before{
			position:absolute;
			content:'\f00c';
			font-family: 'Font Awesome 6 Pro';
  		font-style: normal;
  		font-weight: 900;
  		font-size:inherit;
  		left:-24px;
  		top:0;
  		color:inherit;
		}
	}
	
}
.list-check.list-check--circle{
	li{
		position:relative;
		&:before{
			font-size:0.75rem;
			top:6px;
			left:-20px;
		}
		&:after{
			position:absolute;
			content:'';
			width:20px;
			height:20px;
			border-radius:10px;
			background-color:currentColor;
			opacity:0.2;
			top:4px;
			left:-24px;
		}
	}
}


.list-check--blue li:before{
	color:$darkblue;
}
.list-check--blue.list-check--circle li:after{
	background-color:$darkblue;
	opacity:0.1;
}
.list-check--yellow li:before{
	color:$yellow;
}
.list-check--yellow.list-check--circle li:after{
	background-color:$yellow;
	opacity:0.2;
}
.cta-bg{
	.list-check{
		color:$light;
		li:before{
			color:$yellow;
		}
	}
}
/*
2. uk-list
*/
.uk-list li a,
.uk-list li .uk-button-text{
	text-align: left;
}