/*
1. cart button in nav
2. cart table
3. cart sticky
 */

/*
1. cart button in nav
-----------------------------------------------------------------------------------------------
 */
.cart{
	position:relative;
}
.cart-counter{
	height:24px;
	width:24px;
	border-radius:12px;
	background:$yellow;
	color:$darkblue;
	font-size:0.75rem;
	font-weight:bold;
	pointer-events: none;
	position: absolute;
	top:-6px;
	right:-4px;
	justify-content: center;
	align-items: center;
	display:none;
}
.cart-notification{
	.cart-counter{
		display:flex;
	}

}
.uk-navbar-sticky{
	.uk-button-cart{
		color:$darkblue;
		background:$light;
		border-color:$light;
		position: relative;
		top: -2px;

		svg {
			fill:$darkblue
		}
		&:hover{
			color:$light;
			background:$darkblue;
			border-color:$darkblue;
			svg {
				fill:$light;
			}
		}
	}
}
/*
2. cart table
-----------------------------------------------------------------------------------------------
 */
@media screen and (max-width:$breakpoint-m) {
	.cart-table{
		/* HOSTING */
		.tr-hosting td:nth-child(1) {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: center;
			padding: 0.5rem;
			strong, p {
				display: inline-flex;
				margin:0;
			}
			.uk-button-text {
				padding-right: 0;
			}
			.uk-button-text i:first-child,
			.uk-button-text:hover i:first-child,
			.uk-button-text:active i:first-child,
			.uk-button-text:focus i:first-child
			{
				left: 0;
				opacity: 1;
				position: relative;
				top: 0;
			}
			.uk-button-text span,
			.uk-button-text:hover span,
			.uk-button-text:active span,
			.uk-button-text:focus span
			{
				display:none;
			}
		}
		.tr-hosting td:nth-child(2),
		.tr-hosting td:nth-child(3){
			display: inline-block;
			padding: 8px !important;
			font-size: 1rem;
		}
		/* DOMAINES */
		.tr-domain td:nth-child(1) {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: stretch;
			padding: 0.5rem;
			strong, p {
				display: flex;
				margin:0;
			}
			strong{
				flex: 1 0 auto;
				flex-direction: column;
			}
			.uk-button-text {
				padding-right: 0;
			}
			.uk-button-text i:first-child,
			.uk-button-text:hover i:first-child,
			.uk-button-text:active i:first-child,
			.uk-button-text:focus i:first-child
			{
				left: 0;
				opacity: 1;
				position: relative;
				top: 0;
			}
			.uk-button-text span,
			.uk-button-text:hover span,
			.uk-button-text:active span,
			.uk-button-text:focus span
			{
				display:none;
			}
		}
		.tr-domain td:nth-child(2),
		.tr-domain td:nth-child(3){
			display: inline-block;
			padding: 8px !important;
			font-size: 1rem;
		}
		/* Cloud opslag */
		.tr-cloud td:nth-child(1) {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: center;
			padding: 0.5rem;
			strong, p {
				display: inline-flex;
				margin:0;
			}
			.uk-button-text {
				padding-right: 0;
			}
			.uk-button-text i:first-child,
			.uk-button-text:hover i:first-child,
			.uk-button-text:active i:first-child,
			.uk-button-text:focus i:first-child
			{
				left: 0;
				opacity: 1;
				position: relative;
				top: 0;
			}
			.uk-button-text span,
			.uk-button-text:hover span,
			.uk-button-text:active span,
			.uk-button-text:focus span
			{
				display:none;
			}
		}
		.tr-cloud td:nth-child(2),
		.tr-cloud td:nth-child(3){
			display: inline-block;
			padding: 8px !important;
			font-size: 1rem;
		}
	}
}
/*
3. cart sticky mobile
-----------------------------------------------------------------------------------------------
 */
@media screen and (max-width:$breakpoint-m) {
	.cart-sticky{
		position: sticky;
		bottom: 0;
		z-index: 100;
	}
}

