
.htmx-show-on-request {
  display: none;
}
.htmx-request .htmx-show-on-request {
  display: block;
}
.htmx-hide-on-request {
  display: block;
}
.htmx-request .htmx-hide-on-request {
  display: none;
}
.htmx-disable-on-request {
}
.htmx-request .htmx-disable-on-request {
  position: relative;
  pointer-events: none;
  min-height: 80px;
}
.htmx-request .htmx-disable-on-request:before {
  content: '';
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
  background: rgba(255,255,255,0.85);
  z-index: 201;
}
.htmx-disable-on-request .htmx-loading-message {
  display: none;
}
.htmx-request .htmx-disable-on-request .htmx-loading-message {
  position: absolute;
  left: 0; right: 0;
  top: 0; bottom: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 202;
  display: flex;
  align-items: center;
  justify-content: center;
}