.uk-button{
	font-weight:700;
	border-radius:$border-radius-btn;
	transition: all .25s ease-out;
	border: 2px solid transparent;
	&:hover{
		transition: all .1s ease-in;
	}
}
.uk-button-default{
  /*border-radius:20px;*/
  border-radius:$border-radius-btn;
  background:$darkblue;
  color:$light;
  border: 2px solid $darkblue;
  &:hover,
  &:active,
  &:focus{
  	color:$yellow;
    background:$darkblue;
    border: 2px solid $darkblue;
  }
  &:hover{
  	/*box-shadow: 0 10px 20px -6px rgba(0, 0, 0, 0.5);*/
  	//box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.5);
  	box-shadow:$buttonshadow;
  }
  &:active,
  &:focus{
  	//box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.5);
  	box-shadow:$buttonshadowfocus;
  }
}

/* tekst button with icon - alternative link */
.uk-button-text{
	text-transform: none;
	color:$darkblue;
	position:relative;
	padding-right:20px;
	//transform: translateZ(0);
	box-sizing:border-box;
	-webkit-font-smoothing: subpixel-antialiased;
	span{
		z-index:1;
		position:relative;
		padding:3px 0;
		display:inline-block;
		box-sizing:border-box;
	}
	&:before {
    content: none;
  }
	span:before {
    content: "";
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 100%;
    border-bottom: none;
    transition: right .3s ease-out;
    height:8px;
    background:$yellow;
    z-index:-1;

  }
	span:hover{
		color:$darkblue;
		border-bottom: none;
		padding:3px 0;
	}
	span:hover:before {
 		right: 0;
 		transition: right .3s ease-out;
	}
	i:first-child{
		position:absolute;
		//top:9px;
		top:calc(50% - 7px);
		left:-14px;
		opacity:0;
		transition: left .1s ease-out;
		transition-delay: 0s;
	}
	i:last-child{
		position:absolute;
		//top:9px;
		top:calc(50% - 7px);
		right:0;
		transition: right .1s ease-out;
	}
	&:hover{
		color:$darkblue;
		padding-right:0;
		padding-left:20px;
		transition: padding-left .1s ease-out, padding-right .1s ease-out;
		//transform: translateZ(0);
		box-sizing:border-box;
		i:first-child{
			color:$darkblue;
			top:calc(50% - 7px);
			left:0;
			opacity:1;
			transition: left .1s ease-out;
			transition-delay: 0.15s;
		}
		i:last-child{
			top:calc(50% - 7px);
			right:-14px;
			opacity:0;
			transition: right .1s ease-out;
		}

	}
}

/* tekst button with icon - alternative link back arrows */
.uk-button-text_alt{
	text-transform: none;
	color:$darkblue;
	position:relative;
	padding-left:20px;
	//transform: translateZ(0);
	box-sizing:border-box;
	span{
		z-index:1;
		position:relative;
		padding:3px;
		display:inline-block;
		box-sizing:border-box;
	}
	&:before {
    content: none;
  }
	span:before {
    content: "";
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 100%;
    border-bottom: none;
    transition: right .3s ease-out;
    height:8px;
    background:$yellow;
    z-index:-1;

  }
	span:hover{
		color:$darkblue;
		border-bottom: none;
	}
	span:hover:before {
 		right: 0;
 		transition: right .3s ease-out;
	}
	i:first-child{
		position:absolute;
		top:9px;
		left:0;
		opacity:1;
		transition: left .1s ease-out;
		transition-delay: 0s;
	}
	i:last-child{
		position:absolute;
		top:9px;
		right:0;
		opacity:0;
		transition: right .1s ease-out;
	}
	&:hover{
		color:$darkblue;
		padding-right:20px;
		padding-left:0;
		transition: padding-left .1s ease-out, padding-right .1s ease-out;
		//transform: translateZ(0);
		box-sizing:border-box;
		i:first-child{
			color:$darkblue;
			top:9px;
			left:0;
			opacity:0;
			transition: left .1s ease-out;
			transition-delay: 0.15s;
		}
		i:last-child{
			top:9px;
			right:-14px;
			opacity:1;
			transition: right .1s ease-out;
		}

	}
}


/* 
HEADER tekst button with icon - alternative link 
Also used in CTA block with darkblue gradient image
*/
.ki-header .uk-button-text,
.section-cta .uk-button-text,
.section-extention .uk-button-text{
	text-transform: none;
	color:$light;
	position:relative;
	padding-right:20px;
	//transform: translateZ(0);
	box-sizing:border-box;
	span{
		z-index:1;
		position:relative;
		padding:3px;
		display:inline-block;
		box-sizing:border-box;
	}
	&:before {
    content: none;
  }
	span:before {
    content: "";
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 100%;
    border-bottom: none;
    transition: right .3s ease-out;
    height:8px;
    background:$yellow;
    z-index:-1;

  }
	span:hover{
		color:$light;
		border-bottom: none;
	}
	span:hover:before {
 		right: 0;
 		transition: right .3s ease-out;
	}
	i:first-child{
		position:absolute;
		top:9px;
		left:-14px;
		opacity:0;
		transition: left .1s ease-out;
		transition-delay: 0s;
	}
	i:last-child{
		position:absolute;
		top:9px;
		right:0;
		transition: right .1s ease-out;
	}
	&:hover{
		color:$light;
		padding-right:0;
		padding-left:20px;
		transition: padding-left .1s ease-out, padding-right .1s ease-out;
		i:first-child{
			color:$light;
			top:9px;
			left:0;
			opacity:1;
			transition: left .1s ease-out;
			transition-delay: 0.15s;
		}
		i:last-child{
			top:9px;
			right:-14px;
			opacity:0;
			transition: right .1s ease-out;
		}

	}
}




/*
OLD - CONTACT IS JUST A NAV ITEM NOW
CONTACT BUTTON WHEN NAVBAR TRANSPARENT */
/*
.mainnav:not(.uk-sticky-fixed) .uk-button{
	border:2px solid $light;
	background:none;
	color:$light;
	transition:all 0.5s ease;
	box-shadow:none;
	&:hover{
		border:2px solid $light;
		background:$light;
		color:$darkblue;
		transition:all 0.5s ease;
		box-shadow:none;
	}
}
*/

/*
OLD - CONTACT IS JUST A NAV ITEM NOW
CONTACT BUTTON */
/*
.mainnav .uk-button{
	border:2px solid $darkblue;
	background:$darkblue;
	color:$light;
	transition:all 0.5s ease;
	box-shadow:none;
	&:hover{
		background:$darkblue;
		border:2px solid $darkblue;
		color:$yellow;
		transition:all 0.5s ease;
		box-shadow:none;
	}
}
*/
.uk-button-secondary{
	background:$yellow;
	color:$darkblue;
	border: 2px solid $yellow;
	&:hover,
	&:active,
	&:focus{
		color:$text-inverse;
		background:$darkblue;
		border: 2px solid $darkblue;
	}

}

.uk-button + .uk-button{
	margin-left:2rem;
}

/* BUTTONS IN HEADER & CTA*/
.ki-header,
.section-cta{
	.uk-button-default{
  border-radius:20px;
  background:$darkblue;
  border: 2px solid $darkblue;
  color:$light;
  box-shadow: none;
	  &:hover,
	  &:active,
	  &:focus{
	  	color:$yellow;
	    background:$darkblue;
	    border: 2px solid $darkblue;
		  box-shadow: $buttonshadowfocus;
	  }
	  
	}
}

/* outlined button */
.uk-button.uk-button-outlined{
	color:$darkblue;
	border: 2px solid $darkblue;
	background:$text-inverse;
	transition:all 0.5s ease;
	border-radius:$border-radius-btn;
	&:hover{
		color:$text-inverse;
		border: 2px solid $darkblue;
		background:$darkblue;
		transition:all 0.5s ease;
	}
}
/* outlined button - for select */
.uk-button.uk-button-select{
	font-weight: normal;
	color:$darkblue;
	border: 1px solid $darkblue;
	background:$text-inverse;
	transition:all 0.5s ease;
	border-radius:$border-radius-btn;
	&:hover{
		color:$text-inverse;
		border: 1px solid $darkblue;
		background:$darkblue;
		transition:all 0.5s ease;
	}
}


/*
.uk-button-outlined:hover{
	color:$yellow;
	border: 2px solid $darkblue;
	background:$darkblue;
	transition:all 0.5s ease;
}
*/

.uk-button-cart{
	font-size: 1.25rem;
	padding: 0 15px;
	transition:all 0.5s ease;
	svg {
		fill:$darkblue
	}
	&:hover{
		transition:all 0.5s ease;
		svg {
			fill:white
		}
	}
}

/* button on two lines  */
.uk-button-breaklineheight{
	  line-height: 1;
    width: 100%;
    padding: 1rem;
    border-radius: 32px;
    max-height:64px;
}


/* round button with icon in table */
.button-icon--round{
	background:$light;
	width:24px;
	height: 24px;
	border-radius:50%;
	border:1px solid $bordergrey;
	font-size:1rem;
	i{
			transform: rotate(0deg);
			transition: all 0.5s ease-in;
		}
	&:hover{
		//color: rgba($light,0.5);
		//background:$darkblue;
		i{
			transform: rotate(90deg);
			transition: all 0.25s ease-out;
		}
		
	}
}

/* UK-button-text in blue bg */
.cta-bg,
.section-illustrated{
	.uk-button-text{
		color:$light;
		&:hover,
		&:active,
		&:focus{
			color:$light;
			span,i{
				color:$light;
			}
		}

	}
}
/*secondary button in cta bg */
.skew-section.cta-bg{
	.uk-button.uk-button-secondary:hover{
		border:2px solid $yellow;
	}
}