html{
  width:100%;
  height:100%;
}
html,body{
  margin:0;
  padding:0;
}
body{
  position:relative;
}
.eoy2023-gift{
  position:fixed;
  top:calc(100vh - 113px);
  right:0;
}
/*
body.endOfYear{
  background-image:url(../img/svg/bg-01.svg), url(../img/svg/bg-02.svg), url(../img/svg/bg-03.svg);
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: 330px 457px, 337px 383px, 224px 211px;
  background-position:top 650px right, top 1000px left, top 1600px right;
}
body.endOfYear .header-illustration{
  @media screen and (min-width: $breakpoint-m){
    top:-210px;
  }
  @media screen and (min-width: $breakpoint-l){
    top:-200px;
  }
}
*/
body.endOfYear .header-illustration{
  @media screen and (min-width: $breakpoint-m){
    top:-170px;
  }
  @media screen and (min-width: $breakpoint-l){
    top:-160px;
  }
}

body.endOfYear .ki-header{
  margin-bottom:2rem;
  @media screen and (min-width: $breakpoint-l){
    margin-bottom:0;
  }
}

body.endOfYear .uk-progress{
  margin:1rem 0;
  @media screen and (min-width: $breakpoint-l){
    margin:2rem 0;
  }
}
body.endOfYear .section-illustrated .illustrated > div:first-child img {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 200px;
}
body.endOfYear .section-illustrated .illustrated a{
  color:$yellow;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus{
    text-decoration: underline;
  }
}
/* image next to form hack*/
body.endOfYear .uk-section .image-left{
  > div:first-child{
    width:100%;
  }
  > div:last-child{
    display:none;
  }
}