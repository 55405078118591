/*
videoplayer
https://github.com/sampotts/plyr
 */
:root,
.plyr{
  --plyr-color-main:$yellow;
  --plyr-video-background:$darkblue;
  --plyr-video-control-color:$pink;
  --plyr-video-control-color-hover:$text-inverse;
}