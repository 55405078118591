/*
1. colors
2. font
3. border-radius
4. box-shadow alerts
5. box-shadow cards
6. box-shadow dropdowns
7. breakpoints
*/

/*
1. colors 
*/
$light:#FFFFFF;
$text-inverse:#FFFFFF;

/* MAIN COLOURS*/
$darkblue:#0f27ae;
$yellow:#ffce00;
$lightblue:#0bcfde;
$darkgrey:#444444;

$lightestgrey:#f4f4f4;
$lightgrey:#999999;
$mediumgrey:#888888;
$bordergrey:#cccccc;
$devgrey:#cecece;

$linkcolor:#0f27ad;
$listlinkcolor:#1527b6;

$pink:#ff638d;

$devcardservicebg:#0d1972;

/* SECTION BACKGROUND COLOURS
----------------------------------------------------------------------
*/

$lillalight:#eaecfe;
/*$lillamedium:#e6e9fa;*/
$lilladark:#e1e6fa;
$yellowlight: #fff5cc;

$footerbg:$lilladark;
$darkbg1:$lilladark;

/* lightgrey - lillalight*/
/* lilla - topnav*/
$lightbg1:$lillalight;
/* lilla - border color*/
$cardborder:$lightbg1;


/* GRADIENT LINE header */
$gradientblue:#7ed1f3;
$gradientyellow:$yellow;

/* GRADIENT STROKE */
$brightgreen: #35c848;
$gradientgreen:$brightgreen;


/* GRADIENT COLORS HEADER BG */
$gradientcolor1:#0b27aa;
$gradientcolor2:#374aff;
$gradientcolor3:#17b2f2;

/* GRADIENT cta - legal */
$gradientlegal1:#0b27aa;
$gradientlegal2: #374aff;
$gradientlegal3:#17b2f2;

/* development bg colours */
$bg-dev-01:#060e4c;
$bg-dev-02:#0a1462;
$bg-dev-03:#071259;
$bg-dev-04:#060b37;

/* DANGER RED */
//$color-danger: #f0506e;
$color-danger: #ff0000;

/*
2. font 
*/
/* TEXT */
$fontfamily: 'Open Sans', 'Open Sans Fallback', sans-serif;

/*
3. border-radius
*/
$border-radius:1rem;
$border-radius-large:1.25rem;
$border-radius-input:0.625rem;
$border-radius-btn:20px;

/*
4. box-shadow - alerts - extension card/button
 */
//$boxshadow: 0 5px 15px rgba(0, 0, 0, 0.08);
$extentionshadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);

$buttonshadow:0 10px 20px -6px rgba(0, 0, 0, 0.5);
$buttonshadowfocus:0 0px 5px 0px rgba(0, 0, 0, 0.5);

/*
5. box shadow cards
 */
$cardshadow:0 20px 15px -15px rgba(0,0,0,0.2);

/*
6. box-shadow - dropdowns
 */
$boxshadow: 0 10px 20px rgba(0, 0, 0, 0.25);

$boxshadow-diffused: 0px 50px 20px -45px rgba(0, 0, 0, 0.3);
/*
7. breakpoints
*/
$breakpoint-s: 640px;
$breakpoint-m: 960px;
$breakpoint-small-max:($breakpoint-m - 1);
$breakpoint-l: 1200px;
$breakpoint-xl: 1600px;