/*
1. general styles
2. form-search
3. sidebar form
4. ki-formfield-label
5. ki-select
6. checkboxen & radio's
7. Range selector
8. input-btn-group ( input + buttons & dropdown)
9. input-group
10.freeform checkbox
11.freeform select
12. freeform button submit
13. header-domain-search search form
*/


/*
1. general styles
*/
.uk-input,
.uk-textarea{
	border-radius:$border-radius-input;
	color:$darkblue;
	border: 1px solid $darkblue;
	&:active,
	&:focus{
		color:$darkblue;
		/*border: 1px solid lighten($darkblue,50%);*/
		border: 1px solid $darkblue;
		/*border-style: dashed;*/
	}
}
.uk-textarea{
	padding:10px 17px;
	resize: vertical;
	&:active,
	&:focus{
		border-style: dashed;
	}
}
fieldset{
	border:none;
	padding:0;
}

.uk-form-icon{
	color:$darkblue;
}
form p{
	margin:0 0 0.5rem 0;
}

/*
2. form-search
*/

.form-search {
	width:100%;
	.uk-inline{
		width:100%;
	}
	.uk-input{
		border: 1px solid $light;
		//border: 1px solid $darkblue;
	}
	.uk-input{
		&:active,
		&:focus{
			border: 1px solid $darkblue;
		}
	}

}
.form-search {
	.input-group{
		flex-direction: column;
		@media screen and (min-width: $breakpoint-s){
			flex-direction: row;
		}
		input.uk-input {
			border-top-right-radius: .625rem;
			border-bottom-right-radius: .625rem;
			margin-bottom:1rem;
			@media screen and (min-width: $breakpoint-s) {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				margin-bottom:0;
			}
		}
		.uk-button:not(.uk-button-default) {
			border-top-left-radius: .625rem;
			border-bottom-left-radius: .625rem;
			@media screen and (min-width: $breakpoint-s) {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				border-top-right-radius: .625rem;
				border-bottom-right-radius: .625rem;
			}
		}
	}
}
/*
3. sidebar form
*/
.sidebar-search .form-search{
	.uk-input{
	border-radius:$border-radius-input;
	color:$darkblue;
	border: 1px solid $darkblue;
	&:active,
	&:focus{
		color:$darkblue;
		border: 1px solid $darkblue;
	}
}
}
/*
4. ki-formfield-label
*/
.ki-formfield-label{
	position:relative;
	.uk-form-label{
		font-size:0.675rem;
		color:$mediumgrey;
		background:$text-inverse;
		padding:0 5px 0px 5px;
		position:absolute;
		left:15px;
		top:-8px;
	}
	.uk-input{
		padding-left:17px;
		&:active,
		&:focus{
			border-style:dashed;
		}
	}
}
/*5. ki-select
*/
.ki-select{
	.uk-button-outlined{
		font-weight:normal;
		border:1px solid $darkblue;
		&:hover{
			background:$darkblue;
			color:$light;
		}
	}
}

/*
6. checkboxen & radio's
 */
.uk-checkbox:indeterminate, .uk-radio:indeterminate{
	background-color: $text-inverse;
	background: $text-inverse !important;
	border-color: $darkblue;
}
.uk-radio:checked {
	border-color:$darkblue;
	background-color: $text-inverse !important;
	background-image: url(../img/icons/radiobutton-check.svg);
	background-size: 16px 16px;
	background-repeat: no-repeat;
	background-position: -1px -1px;
}
.uk-checkbox:checked {
	border-color:$darkblue;
	background-color: $text-inverse !important;
	background-image: url(../img/icons/checkbox-check.svg);
	background-size: 16px 16px;
	background-repeat: no-repeat;
	background-position: -1px -1px;
}



.ki-radio,
.ki-check{
	position: relative;
}
.ki-radio,
.ki-check{
	> .uk-form-label {
		font-size: 0.675rem;
		color: $mediumgrey;
		padding: 0 5px 0px 5px;
	}
	.uk-radio,
	.uk-checkbox{
		border-color:$darkblue;

	}
	.uk-form-controls{
		label{
			color:$mediumgrey;
			&:hover{
				cursor: pointer;
			}
		}
	}
}

input.uk-radio:selection,
input.uk-checkbox:selection{
	background: $text-inverse !important;
	color: #fff;
	text-shadow: none;
}


.ki-check--monotone{
	> .uk-form-label {
		font-size: 1rem;
		color: $darkblue;
		font-weight: bold;
		text-transform: uppercase;
		padding: 0;
		margin-bottom:1.5em;
	}
	.uk-radio,
	.uk-checkbox{
		border-color:$darkgrey;

	}
	.uk-form-controls{
		label{
			color:$darkgrey;
			display:block;
			margin-bottom: 0.5em;
		}
	}
	.uk-checkbox:checked {
		border-color:$darkblue;
		background-color: $darkblue !important;
		background-image: url(../img/icons/checkbox-check-white.svg);
		background-size: 16px 16px;
		background-repeat: no-repeat;
		background-position: -1px -1px;
	}
}

/*
7. Range selector
 */
input[type=range] {
	-webkit-appearance: none;
	margin: 18px 0;
	width: 100%;
}
input[type=range]:focus {
	outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
	width: 100%;
	height: 4px;
	cursor: pointer;
	animate: 0.2s;
	background: $lilladark;
	border-radius: 2px;
	border:1px solid transparent;
}
input[type=range]::-webkit-slider-thumb {
	border: 1px solid $darkblue;
	height: 16px;
	width: 16px;
	border-radius: 50%;
	background: $text-inverse;
	cursor: pointer;
	-webkit-appearance: none;
	/*margin-top: -14px;*/
}
input[type=range]:focus::-webkit-slider-runnable-track {
	background: $darkblue;
}
input[type=range]::-moz-range-track {
	width: 100%;
	height: 4px;
	cursor: pointer;
	animate: 0.2s;
	background: $lilladark;
	border-radius: 2px;
	border:1px solid transparent;
}
input[type=range]::-moz-range-thumb {
	border: 1px solid $darkblue;
	height: 16px;
	width: 16px;
	border-radius: 8px;
	background: $text-inverse;
	cursor: pointer;
}
input[type=range]::-ms-track {
	width: 100%;
	height: 4px;
	cursor: pointer;
	animate: 0.2s;
	background: transparent;
	border-color: transparent;
	border-width: 16px 0;
	color: transparent;
}
input[type=range]::-ms-fill-lower {
	background: $lilladark;
	border: 1px solid transparent;
	border-radius: 2.6px;
}
input[type=range]::-ms-fill-upper {
	background: $lilladark;
	border: 1px solid transparent;
	border-radius: 2.6px;
}
input[type=range]::-ms-thumb {
	border: 1px solid $darkblue;
	height: 16px;
	width: 16px;
	border-radius: 8px;
	background: $text-inverse;
	cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
	background: $darkblue;
}
input[type=range]:focus::-ms-fill-upper {
	background: $darkblue;
}
.uk-range{

}

/* 8. input-btn-group */
.input-btn-group{
	color:$darkblue;

	input[type=text],
	.uk-input{
		border-right:none;
		border-right-color:transparent;
		border-top-right-radius:0;
		border-bottom-right-radius:0;
		background:$light;
		&:focus,
		&:active{
			border-style: dashed
		}
	}
	.uk-input:focus + div,
	.uk-input:active + div{
		.uk-button{
			border-style:dashed;
			border-left:none;
		}

	}
	.uk-button{
		padding-left:0;
		border-top-left-radius:0;
		border-bottom-left-radius:0;
		border-top-right-radius:$border-radius-input;
		border-bottom-right-radius:$border-radius-input;
		background:$light;
		font-weight:normal;
		color:currentColor;
		border-top:1px solid currentColor;
		border-bottom:1px solid currentColor;
		border-right:1px solid currentColor;
		border-left:none;
		&:hover,
		&:active,
		&:focus{
			color:$linkcolor;
		}
	}
	> div{
		/*margin-left:-2px;*/
	}
	.uk-drop{
		background:$light;
		box-shadow:$boxshadow-diffused;
		padding:20px;
		border-radius:$border-radius-input;
		border: 1px solid $darkblue;
	}
}

.input-group{
	display:flex;
	span{
		display:inline-block;
		box-sizing:border-box;
		height:42px;
		line-height:40px;
		color:$darkgrey;
		background:$light;
		border-right:none;
		border-right-color:transparent;
		border-top-right-radius:0;
		border-bottom-right-radius:0;
		border-top-left-radius:0.625rem;
		border-bottom-left-radius:0.625rem;
		padding: 0 10px 0 10px;
	}
	.uk-button:not(.uk-button-default){
		border-left:none;
		border-top-left-radius:0;
		border-bottom-left-radius:0;
		border-top-right-radius:0.625rem;
		border-bottom-right-radius:0.625rem;
		padding: 0 20px 0 10px;
		font-weight:normal;
		color:$linkcolor;
		background:$light;
		border: 1px solid $light;
		text-transform: none;
		flex-basis: auto;
		flex-grow: 0;
		&:hover{
			border-color:$darkblue;
		}
	}
	input.uk-input{
		height: 42px;
		border-top-right-radius:0;
		border-bottom-right-radius:0;
		flex-basis: 0;
		flex-grow: 1;
	}
	span + input.uk-input{
		border-top-left-radius:0;
		border-bottom-left-radius:0;
		
	}
	input.uk-input + span{
		border-left:none;
		border-top-left-radius:0;
		border-bottom-left-radius:0;
		border-top-right-radius:0.625rem;
		border-bottom-right-radius:0.625rem;
	}
	span + button[type=submit]{
		margin-left:40px;
	}

}

.form-search{
	button[type=submit]{
		width:100%;
		display:block;
		margin:0.5em 0 0 0;
	}
	@media screen and (min-width: $breakpoint-m){
		display:flex;
		justify-content: space-between;
		width:100%;
		.input-group{
			flex-basis: 0;
			flex-grow: 1;
		}
		button[type=submit]{
			flex-basis: auto;
			width:auto;
			display:inline;
			margin:0 0 0 0.5em;
		}
	}
}

/*
10.freeform checkbox
 */
.freeform-input[type="checkbox"],
.freeform-row .freeform-column .freeform-input[type="checkbox"]{
	margin:0 12px 0 0;
	margin-right: 12px !important;
}

/*
11.freeform select
 */
select.freeform-input,
.freeform-row .freeform-column select.freeform-input{
	border: 1px solid $darkblue;
	border-radius: .625rem;
	color: $darkblue;
	padding: 0 32px 0 10px;
	display: inline-block;
	height: 40px;
	vertical-align: middle;
	background-color: transparent;
	font:inherit;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: url(../img/icons/select-dropdown.svg) 96% / 15% no-repeat #FFFFFF;
	background-size: 16px;

}
/*
12.freeform-button-submit
 */
button[type="submit"].freeform-button-submit{
	/*border-radius:20px;*/
	border-radius:$border-radius-btn;
	background:$darkblue;
	color:$light;
	border: 2px solid $darkblue;
	&:hover,
	&:active,
	&:focus{
		color:$yellow;
		background:$darkblue;
		border: 2px solid $darkblue;
	}
	&:hover{
		/*box-shadow: 0 10px 20px -6px rgba(0, 0, 0, 0.5);*/
		//box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.5);
		box-shadow:$buttonshadow;
	}
	&:active,
	&:focus{
		//box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.5);
		box-shadow:$buttonshadowfocus;
	}
}

/* CAUTION: Internet Explorer hackery ahead */

select::-ms-expand {
	display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
}

/*
13. header-domain-search search form
*/

@media screen and (max-width: $breakpoint-m){
	.header-domain-search .form-search,
	.homepage .cta-bg .form-search{
		.input-group input.uk-input{
			height: 40px;
			min-height: 40px;
		}
		a.uk-button{
			background: transparent;
			color:#ffffff;
			text-decoration: none;
			&:hover,&:active,&:focus{
				background: transparent;
				color:#ffffff;
				text-decoration: underline;
			}
		}
	}
	.ki-header:has(.form-search),
	.cta-bg:has(.form-search){
		.input-group{
			flex-direction: row;
		}
		.input-group input.uk-input{
			height: 40px;
			min-height: 40px;
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
			border-top-left-radius: $border-radius-input;
			border-bottom-left-radius: $border-radius-input;
			margin: 0;
		}
		.input-group span{
			background: rgba(255, 255, 255, 1);
			height: 40px;
		}

		.input-group span:last-child{
			border-top-right-radius:  $border-radius-input;
			border-top-left-radius: 0;
			border-bottom-right-radius: $border-radius-input;
			border-bottom-left-radius: 0;
		}
	}
	.well:has(.form-search){
		padding: 1.25rem;
	}
	.ki-header:has(.form-search) .uk-button-default{
		padding:0 15px;
	}
}