#locale-modal{
  .uk-button.uk-button-link.uk-margin-left{
    margin-left:0 !important;
  }
  .uk-button.uk-button-link:hover,
  .uk-button.uk-button-link:active,
  .uk-button.uk-button-link:focus{
    color:$darkblue;
    background: transparent;

  }
}