.skew-section{
	transform: skewY(-6deg);
	.uk-container{
		transform: skewY(6deg);
	}
}
.skew-section + .skew-section{
	margin-top:-10px;
}
.uk-section:not(.skew-section):not(.trianglebg):not(.cta-bg):not(.section-illustrated):not(.section-cta) + .skew-section{
	//margin-top: 8rem;
	margin-top:70px;
}
/* SKEW BOTTOM OR TOP - LIGHTBLUE BG */ 
.section-skew-bottom{
	position: relative;
	background:$lightbg1;
	//margin-bottom: 12vw !important;
	min-height: 200px;
	padding-bottom: 100px;
	&:after{
		content: '';
	  width: 100%;
	  height: 100%;
	  //height:8.854166666666668VW;
	  position: absolute;
	  background: inherit;
	  z-index: -1;
	  bottom: 0;
	  transform-origin: right bottom;
	  transform: skewY(-6deg);

	}
	.ki-subtitle,
	p{
		color:$darkgrey;
	}
}

.section-skew-top{
	position: relative;
	background:$lightbg1;
	margin-top: 12vw !important;
	min-height: 200px;
	&:after{
		content: '';
	  width: 100%;
	  height: 100%;
	  //height:8.854166666666668VW;
	  position: absolute;
	  background: inherit;
	  z-index: -1;
	  top: 0;
	  transform-origin: left top;
	  transform: skewY(-6deg);

	}
	.ki-subtitle,
	p{
		color:$darkgrey;
	}
}


.section-fw{
.ki-subtitle,
	p{
		max-width:750px;
		margin:0 auto;
		text-align: center;
	}
}
.skew-section.lightbg{
	padding:170px 0;
}
.lightbg{
	background:$lightbg1;
}
.darkbg{
	background:$darkbg1;
}
.trianglebg{
	//color:$darkblue;
	padding:170px 0;
	background:url(../img/bg/bg-cards-1x.jpg);
	background-position:center center;
	background-size:cover;
	@media screen and (min-width:1920px){
		background:url(../img/bg/bg-cards-2x.jpg);
		background-position:center center;
		background-size:cover;
	}
}
.cta-bg{
	color:$light;
	padding:75px 0;
	background:url(../img/bg/bg-cta-1x.jpg);
	background-position:center center;
	background-size:cover;
	z-index:2;
	@media screen and (min-width:1920px){
		background:url(../img/bg/bg-cta-2x.jpg);
		background-position:center center;
		background-size:cover;
		z-index:2;
	}
	h1.ki-heading,
	h1{
		color:$yellow;
		background-image:none;
		font-weight: bold;
	}
	h2.ki-heading,
	h2{
		color:$text-inverse;
		background-image:none;
		font-weight: normal;
	}
}
.darkblue-bg{

	padding:75px 0;
	background:url(../img/bg/bg-cta-1x.jpg);
	background-position:center center;
	background-size:cover;
	z-index:2;
	@media screen and (min-width:1920px){
		background:url(../img/bg/bg-cta-2x.jpg);
		background-position:center center;
		background-size:cover;
		z-index:2;
	}
	h1.ki-heading,
	h1{
		color:$yellow;
		background-image:none;
		font-weight: bold;
	}
	h2.ki-heading,
	h2{
		color:$text-inverse;
		background-image:none;
		font-weight: normal;
	}
}
.section-promo{
	h1,h2{
		color:$yellow;
		font-weight: bold;
		text-align: center;
	}
	background:url(../img/bg/bg-section-promo.webp);
	@media screen and (min-width:1920px){
		//background:url(../img/bg/bg-cta-2x.jpg);
	}

}

section{
	position:relative;
	z-index: 1;
	.uk-dropdown{
		z-index:100;
	}
	.uk-grid{
		@media screen and (max-width:$breakpoint-m){
			.col-text{
				order:1;
			}
			.col-img{
				order:2;
			}
		}
	}
}
section.skew-section{
	@media screen and (max-width:$breakpoint-m){
		.ki-heading{
			margin-top:2.5rem;
		}
	}
}

section + section{
	/*margin-top:4.375rem;*/
	z-index: 0;
}
section + footer{
	/*margin-top:4.375rem;*/
	z-index: 0;
}
section.skew-section:not(.cta-bg) + footer[style]{
	margin-top:70px !important;
}
section.uk-section:not(.skew-section) + footer[style]{
	margin-top:170px !important;
	@media screen and (min-width:$breakpoint-l){
		margin-top:170px !important;
	}
}
section.skew-section + section:not(.skew-section){
	margin-top:4.375rem;
}
.section-cta{
	z-index: 500;
}

.section-diagonal{
	position:relative;
	/*padding-bottom:18.229166666666664VW;*/
	padding-bottom:0;
	/* margin-bottom --> zelfde hoogte als :after van de diagonale svg - bottom:-67px*/;
	margin-bottom:67px;
	.diagonal-bottom{
		height:18.229166666666664VW;
		position:absolute;
	  bottom:0;
	  left:0;
	  width:100%;
	  &:after{
	  	content:'';
	  	position: absolute;
	  	background:$lightbg1;
	  	bottom:-67px;
	  	left:0;
	  	width:100%;
	  	height:70px;
	  }
	}
}
/* NOT SURE IF NEEDED */
/*
section.section-diagonal + section:not(.skew-section){
	background:$lightbg1;
}*/

/* SECTION SUPPORT CARDS - IRREGULAR SPACING OFD CARDS */
.section-supportcards:first-of-type{
	@media screen and (min-width:$breakpoint-m){
		padding-top:0;
		margin-top: -5rem;
	}
	@media screen and (min-width:$breakpoint-l){
		padding-top:0;
		margin-top: -12rem;
	}
	.uk-grid > div:nth-child(odd){
		@media screen and (min-width:$breakpoint-m){
			padding-top:0;
		}
		@media screen and (min-width:$breakpoint-l){
			padding-top:4rem;
		}
	}
}
/* SECTION FAQ ICONS - HOVER */
.section-faqicons .uk-panel a img{
	transition:all 0.5s ease;
	transform:rotate(0deg);
}
.section-faqicons .uk-panel a:hover img{
	transform:rotate(-10deg);
	transition:all 0.25s ease;
}

/* FIRST AND LAST SECTION OF PAGE */
body > .uk-section:not(.skew-section):not(.trianglebg):not(.cta-bg):not(.section-illustrated):not(.section-cta):first-of-type{
	padding-top:0;
}
body > .uk-section:not(.skew-section):not(.trianglebg):not(.cta-bg):not(.section-illustrated):not(.section-cta):last-of-type{
	padding-top:0;
	padding-bottom: 50px;
	@media screen and (min-width: $breakpoint-l){
		padding-top:70px;
		padding-bottom: 70px;
	}
}
/*
body > .uk-section:last-of-type{
	padding-bottom:0;
	@media screen and (min-width: $breakpoint-l){
		padding-bottom:0;
	}
}
*/

/* FIXES WHEN STICKY SUBNAV IS USED */
.subnav-sticky-fw + .uk-section:not(.skew-section):not(.trianglebg):not(.cta-bg):not(.section-illustrated):not(.section-cta),
.subnav-sticky-fw ~ .uk-section:not(.skew-section):not(.trianglebg):not(.cta-bg):not(.section-illustrated):not(.section-cta){
	padding-top:0;
	@media screen and (min-width: $breakpoint-l){
		padding-top:70px !important;
	}
}