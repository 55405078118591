/*
1. styling single pricebox (circle badge in header)
2. slider pricecards for mobile
3. Pricebox in header
 */

/*
1. styling single pricebox
----------------------------------------------------------
 */
.pricebox{
	width:150px;
	height:150px;
	background:url(../img/svg/pricebox.svg);
	background-repeat: no-repeat;
	background-position:center center;
	background-size:100% 100%;
	padding:6px;
	line-height: 1.2;
	display:flex;
	flex-direction: column;
	justify-content: space-between;
	text-align:center;
	.top{
		padding-top:25px;
		//min-height:42px;
		box-sizing: border-box;
	}
	.bottom{
		padding-bottom:15px;
		box-sizing: border-box;
	}
	.price-number{
		font-size:1.3125rem;
	}
	.price-from{
		display:block;
	}
	.price-duration,
	.price-from,
	.price-old .price-number{
		font-size:0.75rem;
	}
	.price, .price-old{
		margin-bottom: 4px;
	}
	p,i{
		color:$darkblue;
		margin:0;
	}
	.logo{
		max-width:100px;
		max-height: 25px;
	}
}

.pricebox + .pricebox{
	margin-left:0.5em;
}
/*
2. slider priccards for mobile
-----------------------------------------------------
 */
.uk-slider{
	.card-pricebox, .uk-card{
		//max-width:300px;
		max-width: calc(100vw - 150px);
		margin:0 auto;
		@media screen and (min-width:$breakpoint-s){
			max-width: calc(100vw - 200px);
		}
		@media screen and (min-width:$breakpoint-m){
			max-width: 100%;
		}
	}
	@media screen and (min-width:$breakpoint-m){
		.uk-slider-container{
			pointer-events: none;
		}
		.uk-slider-container .uk-button,
		.uk-slider-container a
		{
			pointer-events:auto;
		}
	}
}
/* OFFSET FOR DROPSHADOW */
.uk-slider.uk-slider-container-offset,
.uk-slider .uk-slider-container.uk-slider-container-offset{
	margin: -11px -25px -39px -25px;
	padding: 11px 25px 39px 25px;
}
/*
3. Pricebox in header
----------------------------------------------------------------------------------------------------------
 */

.ki-header:has(.pricebox){
	.pricebox .middle{
		.price-duration,
		.price-from,
		.price-old .price-number{
			font-size:1rem;
		}
		span{
			display:block;
		}
	}
}