/* CONTACT BLOCK IN SECTIONS */
.contact{
	max-width:750px;
}
.contact-item{
	background: $light;
	border:1px solid $bordergrey;
	border-radius:$border-radius-large;
	padding:30px;
	display:flex;
	/*justify-content: space-between;*/
	/*align-items: center;*/
	text-align: center;
	box-shadow:$boxshadow-diffused;
	p{
		margin:0.5rem 0 1.75rem 0;
		line-height:1.5;
	}
	p:first-child{
		font-weight:bold;
		font-size:1.25rem;
		color:$darkblue;
		/*line-height:1.2;*/
		/*padding-right:40px;*/
	}
	p:last-child{
		flex-basis:content;
	}
}
.tobottom-100{
	position: absolute;
  bottom: -100px;
}

/* CONTACT PERSON - NEWS - SUPPORT ITEMS */

.contact-person{
	min-width:250px;
	max-width:600px;
	margin:30px 0;
	@media screen and (min-width:$breakpoint-m){
		margin:50px 0;
	}
	.contact-item{
		box-shadow:none;
	}
	p:last-child{
	
		line-height:1.4;
	}
	a.uk-button{
		margin:1.5em 0;
	}
}
.contact-img{
	border-radius:75px;
}
.contact-name{
	display:block;
	font-weight:bold;
	color:$darkblue;
}
.contact-funct{
	display:block;
}


/* CONTACT WITH TITLE */
.contact-with-title{
	.contact-item{
		display:flex;
		flex-direction: column;
		padding: 1rem;
		@media screen and (min-width:$breakpoint-m){
			padding: 30px;
		}
		> div{
			display:flex;
			flex-direction: column;
			>div:first-child{
				padding-right: 0;
				@media screen and (min-width: $breakpoint-m){
					padding-right: 40px;
				}

			}
		}
		p {
			font-weight: normal;
			font-size: 1rem;
			color: $darkgrey;
			line-height: 1.5;
		}
	}
	h5{
		font-weight:bold;
		font-size:1.5rem;
		text-align: center;
		margin:0 0 0.5em 0;
		width:100%;
	}
	.uk-button.uk-button-default{
		width: fit-content;
		margin: 0 auto;
	}
}