/*
1. General typo & Ki-heading
2. text colors succes, info, danger
3. ki heading development pages
 */
h1,h2,h3,h4,h5,h6{
  color:$darkblue;
  //font-weight:300;
  strong{
    font-weight:700;
  }
}
em {
  color: black;
}
/*
.ki-heading{
  position:relative;
  font-weight:700;
  span{
    z-index:1;
    position:relative;
    padding:0 5px;
  }
  span:after{
    content:'';
    position:absolute;
    width:100%;
    height:15px;
    left:0;
    bottom:0;
    background:$lightblue;
    z-index: -1;
  }
}
*/
.ki-heading{
  position:relative;
  font-weight:700;
  z-index:1;
  padding:0 5px;
  /*display:inline-block;*/
  span{
    z-index:1;
    position:relative;
    padding:0 5px;
  }
  /*&:after{
    content:'';
    position:absolute;
    width:100%;
    height:15px;
    left:0;
    bottom:0;
    background:$lightblue;
    z-index: -1;
  }*/
  /*span:after{
    content:'';
    position:absolute;
    height:15px;
    width:100%; 
    left:0;
    bottom:0;
    border-bottom:15px solid $lightblue;
    z-index: -1;
  }*/
  display:inline;
  background-image: linear-gradient($lightblue, $lightblue);
  background-position: 0% 98%;
  background-repeat: no-repeat;
  background-size: 100% clamp(10px,2vw,10px);
  transition: background-size .3s;
}
.ki-heading + * {

  //margin-top: 30px !important;
  /*@media screen and (min-width: $breakpoint-l){
    margin-top: 40px !important;
  }*/

  margin-top: min(30px, 40px) !important;
}
.ki-heading + *:not(.uk-grid) {
  display:block;
  //margin-top: 30px !important;
  /*@media screen and (min-width: $breakpoint-l){
    margin-top: 40px !important;
  }*/

  
}
.footer-content .ki-heading{
  span{
    padding:0 3px;
  }
  &:after{
    height:10px;
  }
}
.ki-heading ~ p{

}

h1,
.skewed-bg h1,
.ki-header h1{
  
  z-index: 1;
 /* backface-visibility: hidden;
  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;*/
  font-size: 2rem;
  @media screen and (min-width:$breakpoint-l){
    font-size: 2.5rem;
    //font-size: clamp(1.875rem, calc(2rem + 1vw) , 3rem);
  }
  //font-size: clamp(1.875rem, calc(2rem + 1vw) , 3rem);

}

h2.ki-heading,
h2{
  font-size: 1.875rem;
  @media screen and (min-width:$breakpoint-l){
    font-size: 2.3rem;
    //font-size: clamp(1.875rem, calc(2rem + 1vw) , 2.5rem);
    //font-size: clamp(1.75rem, 5vw , 2.75rem);
     //font-size: clamp(1.75rem, 5vw , 3rem);
  }
  //font-size: clamp(1.875rem, calc(2rem + 1vw) , 2.5rem);
   //font-size: clamp(1.75rem, 5vw , 2.75rem);
    //font-size: clamp(1.75rem, 5vw , 3rem);
}
.ki-header h2{
  font-size: 1.875rem;
   @media screen and (min-width:$breakpoint-l){
    font-size: 2rem;
    //font-size: clamp(1.875rem, calc(2rem + 1vw) , 2.5rem);
    //font-size: clamp(1.75rem, 5vw , 2.75rem);
     //font-size: clamp(1.75rem, 5vw , 3rem);
   }
}

h3{
  font-size: 1.5rem;
  font-size: clamp(1.5rem, 3vw, 2rem);
  @media screen and (min-width:$breakpoint-m){
    font-size: 1.875rem;
    //font-size: clamp(1.5rem, calc(1.5rem + 1vw) , 1.875rem);
   // font-size: clamp(1.5rem, 3vw, 2rem);
  }
}

.ki-subtitle{
  font-family:$fontfamily;
  font-weight:400;
  line-height:1.4;
  margin-bottom: 1.875rem;
 
  color:$darkblue;
  //font-size: 1.5rem;
  font-size: 1.35rem;
  @media screen and (min-width:$breakpoint-m){
    //font-size: 1.875rem;
    font-size: 1.5rem;
    //font-size: clamp(1.5rem, calc(1.5rem + 1vw), 2rem);
    //font-size: clamp(1.5rem, 3vw, 2rem);
    //font-size: clamp(1.35rem, 3vw, 2rem);
  }
  //font-size: clamp(1.5rem, calc(1.5rem + 1vw) , 1.875rem);
  //font-size: clamp(1.5rem, 3vw, 2rem);
  //font-size: clamp(1.35rem, 3vw, 2rem);

}
.ki-subtitle + *{
  margin-top:min(30px,40px);
}

h4{
  font-size: 1.25rem;
}
p{
  line-height:2;
}

/* HEADERS IN FOOTER */
footer .ki-heading{
  background-size: 100% clamp(5px, 0.5vw, 8px);
}
/*
2. text colors succes, info, danger
*/
.uk-text-primary{
  /*color:darken($lightblue,10%) !important;*/
  color:$darkblue !important;
}

.uk-text-success{
  color:$brightgreen !important;

}
.uk-text-danger{
  color:$color-danger !important;

}

