.panel-link{
	text-decoration: none;
	&:hover,
	&:active,
	&:focus{
		text-decoration: none;
	}
}
.section-faqicons .uk-panel h4{
	font-weight:400;
}

/* PANEL LOGO
.panel-logo{
	border:1px solid $bordergrey;
	border-radius:20px;
	padding:20px;
	display:flex;
	justify-content: center;
	align-items: center;
	min-height:180px;
	img{
		max-width: 215px;
		max-width: 140px;
	}
}
 */
/* panel logo with text */
.panel-logo{
	border:1px solid $bordergrey;
	border-radius:$border-radius-large;
	
	display:flex;
	flex-direction: column;
	justify-content: center;
	> div:first-child{
		min-height:125px;
		display:flex;
		justify-content: center;
		align-items: center;
		padding:20px;
		box-sizing: border-box;
	}
	img,svg{
		max-width: 150px;
		//max-width: 140px;
		max-height:100px;
		//aspect-ratio:auto;
		object-fit: contain;
	}
	p{
		margin:0 20px 20px 20px;
		 display: -webkit-box;
  	-webkit-line-clamp: 4;
  	-webkit-box-orient: vertical;  
  	overflow: hidden;
	}
	&.panel-flex div:first-child{

	}
	&.panel-flex h4,
	&.panel-border h4{
		margin:20px 0 0 0;
	}
}

/* panel wizzard */
.panel-wizzard{
	height:100%;
	border:1px solid $bordergrey;
	border-radius:$border-radius-large;
	padding:1.25em 1.25em 1.875em 1.25em;
	text-align:center;
	transition:border 0.5s ease-out, background 0.5s ease-out;

	display:flex;
	flex-direction: column;
	justify-content: space-between;
	&:hover{
		border:1px solid $darkblue;
		background:$lightbg1;
		transition:border 0.25s ease-out, background 0.5s ease-out;
	}
}
.panel-wizzard-image{
	flex-grow: 0;
	
}
.panel-wizzard-title{
	flex-grow: 0;
	h3{
		font-weight: bold;
		font-size:1.5rem;
	}

}
.panel-wizzard-price{
	flex-grow: 0;
	.price-number{
		font-size:1.75rem;
		
	}
}
.panel-wizzard-desc{
	padding:2em 0 3em 0;
	flex-grow: 1;
	p:last-child{
		margin:0;
	}
}
.panel-wizzard-actions{
	flex-grow: 0;
}

/* SELECTED PANEL */
.panel-wizzard.selected{
	border:1px solid $darkblue;
	background:$lightbg1;
	transition:border 0.25s ease-out, background 0.5s ease-out;
}
.uk-button.selected{
	color:$yellow;
}

/* COLOURED PANEL */
.bg-light,
.panel-flex.bg-light{
	background:$lillalight;
	
}
.panel-coloured{
	border-radius: 1.25rem;
	padding:20px;
}

.panel-background{
	border-radius: 1.25rem;
	padding:20px;
}


/* PANEL grey border */
.panel-border{
	padding:$border-radius-large;
	border-radius:$border-radius-large;
  	border: 1px solid $bordergrey;
	background: $text-inverse;
}


/* PANEL GRADIENT LINE */
.panel-gradient,
.panel-gradient.panel-flex{
	padding:$border-radius-large;
	/*border-radius:$border-radius-large;
  	border: 1px solid transparent;*/
  //border-image-source: linear-gradient(45deg, $yellow, $gradientgreen, $lightblue);
  //border-image-slice: 1;
  //background:$light;
	background: linear-gradient(white, white) padding-box, linear-gradient(45deg, $yellow, $gradientgreen, $lightblue) border-box !important;
	border-radius: $border-radius-large;
	border: 1px solid transparent;
  position:relative;
  z-index: 100;
	height: 100%;
  >*{
  	 z-index: 100;
  }
  /*&:before{
  	position: absolute;
  	content: '';
  	width:calc(100% - 2px);
  	height:calc(100% - 2px);
  	top:1px;
  	left:1px;
  	//bottom:-1px;
  	//right:-1px;
  	background:$light;
  	//border-radius:$border-radius-large;
  	border-radius:1.2rem;
  	z-index: 0;
  }
  &:after{
  	position: absolute;
  	content: '';
  	width:100%;
  	height:100%;
  	top:0;
  	left:0;
  	bottom:0;
  	right:0;
  	background:linear-gradient(45deg, $yellow, $gradientgreen, $lightblue);
  	border-radius:$border-radius-large;
  	z-index: -1;
  }*/
}

.panel-header{
	display:flex;
	align-items: center;
	margin-bottom:0.5em;
	img{
		width:24px;
		height:24px;
		/*margin-right:1.5em;*/
		margin-right:1em;
		flex-basis: 0;
		flex-grow: 0;
	}
	h4{
		margin:0;
		font-size:1.5rem;
		font-size:clamp(1.25rem,2vw,1.5rem);
		line-height: 1.2;
	}
	
}

/* panel COMMON FLEX PROPERTIES */
.panel-flex{
	display:flex;
  flex-direction: column;
  justify-content: space-around;
	background: $text-inverse;
	/*height: 100%;*/
  div:first-child,
   div:last-child{
  	flex-basis: 0;
  	flex-grow: 0;
  }
  div:nth-child(2){
  	flex-basis: 0;
  	flex-grow: 1;
  }		
}
.panel-grid .uk-panel{
	height:100%;
}

/* P TAGS IN PANELS */
.uk-panel.panel-border,
.uk-panel.panel-gradient,
.uk-panel.panel-flex{
	p{
		margin:0 0 0.5em 0;
		line-height:1.4;
	}
	h4{
		 /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
	}
}