.uk-pagination>*>* {
    display: block;
    color: $mediumgrey;
    transition: color .1s ease-in-out;
}
.uk-pagination {
	> *{
		padding-left:15px;
		@media screen and (min-width: $breakpoint-m){
			padding-left:30px;
		}
	}
	.uk-active > *{
		color:$darkblue;
	}
	a:hover{
	color:$linkcolor;
	}
	.uk-disabled > *{
		color:$lightgrey;
	}
}
