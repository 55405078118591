/* not used */
//.section-txt-image,
//.section-image-text{
//	/*.uk-grid > div[class*=uk-width]{
//		display:flex;
//		flex-direction: column;
//		align-items: center;
//	}*/
//	img{
//		@media screen and (max-width: $breakpoint-l){
//			display:block;
//			margin:0 auto;
//		}
//	}
//	> div{
//		@media screen and (max-width: $breakpoint-l){
//			max-width:750px;
//			margin:0 auto;
//		}
//	}
//}
//.section-image-text{
//	@media screen and (max-width: $breakpoint-l){
//		.uk-grid{
//			flex-wrap: wrap-reverse;
//		}
//	}
//}
/*end not used*/
/* text first on mobile - image left/right */
.image-left {
  > div:first-child {
    order: 2;
  }

  > div:last-child {
    order: 1;
  }

  @media screen and (min-width: $breakpoint-l) {
    > div:first-child {
      order: 1;
    }
    > div:last-child {
      order: 2;
    }
  }
}

.section-illustrated {
  z-index: 2;

  > div {
    position: relative;
    /*min-height:200px;*/
    &:before {
      position: absolute;
      content: '';
      height: 85px;
      width: 100%;
      left: 0;
      top: -140px;
      background: url(../img/elems/cta-ill-center.png);
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 40px 85px;
      transform: skewY(6deg);
    }
  }

  .illustrated {
    padding: 0;
    display: flex;
    min-height: 300px;

    > div:first-child,
    > div:last-child {
      flex-basis: 200px;
      flex-shrink: 0;
    }

    h1, h2, h3, h4, h5, h6 {
      color: $yellow;
      font-weight: bold;
    }

    > div:first-child img {
      position: absolute;
      left: 0;
      bottom: -200px;
      width: 200px;
    }

    > div:last-child img {
      position: absolute;
      right: 0;
      top: -200px;
      width: 200px;
    }
  }

  @media screen and (max-width: $breakpoint-m) {
    .illustrated {
      padding: 0 40px;

      > div:first-child,
      > div:last-child {
        display: none;
      }
    }
  }
}

/* SECTION WITH BLOCKQUOTE */
.section-blockquote {
  > .uk-container > * {
    margin-left: 10vw;
    max-width: 65vw;
    @media screen and (min-width: $breakpoint-s) {
      margin-left: 80px;
      //max-width:360px;
    }
    @media screen and (min-width: $breakpoint-m) {
      margin-left: 120px;
      //max-width:600px;
    }
    @media screen and (min-width: $breakpoint-l) {
      margin-left: 250px;
      max-width: 750px;
    }
  }

  blockquote {
    font-size: 1.35rem;
    @media screen and (min-width: $breakpoint-m) {
      //font-size: 1.875rem;
      font-size: 1.5rem;
      //font-size: clamp(1.5rem, calc(1.5rem + 1vw), 2rem);
      //font-size: clamp(1.5rem, 3vw, 2rem);
      font-size: clamp(1.35rem, 3vw, 2rem);
    }
    //font-size: clamp(1.5rem, calc(1.5rem + 1vw) , 1.875rem);
    //font-size: clamp(1.5rem, 3vw, 2rem);
    font-size: clamp(1.35rem, 3vw, 2rem);
    line-height: 2;
    color: $linkcolor;
    position: relative;

    &:before,
    &:after {
      position: absolute;
      content: '';
      height: 63px;
      width: 37px;
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 37px 63px;
      left: -50px;
    }

    &:before {
      top: calc(50% - 63px);
      /*transform: translate(0, -55%);*/
      background: url(../img/elems/blockquote-top.png);
    }

    &:after {
      top: 50%;
      /*transform: translate(0, -45%);*/
      background: url(../img/elems/blockquote-bottom.png);
    }
  }
}

/* SECTION WITH LARGE ICONS */
.section-icons {
}

.icons {
  margin: 40px 0;
  @media screen and (min-width: $breakpoint-m) {
    margin: 60px 0;
  }
}

.icons--large {
}

.icon-item {
  padding: 0 20px;
  width: min(215px, 250px);
  box-sizing: border-box;
}

.icon-image {
  display: block;
  width: 130px;
  height: 130px;
  margin: 0 auto;
}

.icon-text {
  text-align: center;
  line-height: 1.2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* SECTION 2 COL LIST WITH ICONS */
.section-list-icons {
}

.iclist-icon {
  display: flex;
  flex-wrap: wrap;
}

.iclist-item {
  width: 100%;
  display: flex;
  @media screen and (min-width: $breakpoint-s) {
    width: 50%;
    box-sizing: border-box;

  }

  h3 {
    font-weight: normal;
    margin: 0 0 13px 0;
  }

  p {
    padding-right: 10px;
    margin: 0 0 30px 0;
  }

  img {
    width: 55px;
    height: auto;
  }

  > div:first-child {
    width: 70px;
    flex-basis: 70px;
    flex-shrink: 0;
  }

  > div:last-child {
    padding-right: 0;
    @media screen and (min-width: $breakpoint-s) {
      padding-right: 40px;
    }
  }
}

.uk-list.iclist-icon > li:nth-child(n+2) {
  margin-top: 0;
}