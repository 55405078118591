.alert,
.uk-alert,
.uk-text-danger{
  color:$color-danger;
}


.uk-alert.uk-alert-info{
  color:darken($lightblue,10%) !important;
  background:lighten($lightblue,50%) !important;
  border-radius:$border-radius;
}
.uk-alert.uk-alert-success{
  color:darken($gradientgreen,10%) !important;
  background:lighten($gradientgreen,45%) !important;
  border-radius:$border-radius;
}
.uk-alert.uk-alert-danger{
  color:$color-danger !important;
  background:lighten($color-danger,40%) !important;
  border-radius:$border-radius;
}
