/* 

1. article sidebar / nav
2. article
*/
/* 
1. article sidebar / nav
*/
.sidebar-nav{
	ul{
		margin:1rem 0 2rem 0;
		 @media screen and (min-width: $breakpoint-m){
			margin:2rem 0 2rem 0;
		}
	}
	ul > li > a{
		color: $darkgrey;
		position:relative;
		transition:all 0.25s ease;
		@media screen and (min-width: $breakpoint-m){
			padding:1.25rem 0;
		}
		font-size:1rem;
		&:hover{
			color: $darkblue;
	    font-weight:bold;
	    padding-left:1.5rem;
	    transition:all 0.25s ease;
	    &:before{
	    	font-family: "Font Awesome 6 Pro";
	    	position:absolute;
	    	content: "\f105";
	    	top:0.25rem;
	    	@media screen and (min-width: $breakpoint-m){
	    		top:1.25rem;
	    	}
	    	left:0;
	    	height:100%;
	    	width:1.5rem;
	    }

		}
	}
	.uk-nav-default>li.uk-active>a {
    color: $darkblue;
    font-weight:bold;
    padding-left:1.5rem;
    transition:all 0.25s ease;
    &:before{
    	font-family: "Font Awesome 6 Pro";
    	position:absolute;
    	content: "\f105";
    	top:0.25rem;
    	@media screen and (min-width: $breakpoint-m){
    		top:1.25rem;
    	}
    	left:0;
    	height:100%;
    	width:1.5rem;
    }
	}
	.uk-nav-header{
		font-weight: bold;
		color:$darkblue;
		text-transform: none;
		font-size:1.25rem;
	}
}
div[class^='sidebar-'],
div[class*=' sidebar-']{

	h4{
		font-weight: bold;
		color:$darkblue;
		text-transform: none;
		font-size:1.25rem;
	}
}
aside{
	hr{
		margin-top:20px;
		@media screen and (min-width: $breakpoint-m){
			margin-top: 4rem;
		}
	}
}
/*
2.article
*/
.search-results-single{
	hr{
		margin: 4rem 0 4rem 0;
	}
}
.article-faq{
	.article-meta{
		color:$darkgrey;
	}
	h1,h2,h3,h4,h5,h6{
		font-weight: bold;
	}
	h2{
		font-size:1.875rem;
	}
	h3{
		 font-size: 1.5rem;
	}
	h4{
  	font-size: 1.25rem;
	}
	a{
		text-decoration: underline;
	}
	code{
		margin:0.5rem 0 1.25rem 0;
		display: inline-block;
	}
}
/*
3. Related article
*/
.related-article{
	display:flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	flex-direction: column;
	height: 100%;
	h4{
		min-height:56px;
		min-height:calc((1.25rem * 1.5) *2);
	}
	p:first-of-type{
		flex:1 0 0;
	}
}