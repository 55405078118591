/*
1. Regular table - prices in regular table
2. domain name table
3. SSL table
4. table in cards
5. table extentions prices - promo
6. large comparison table for products - prices-buttons
 */

/*
1. Regular table
*/

.uk-table th {
  color: $darkgrey;
}
.uk-table th,
.uk-table td {
    padding: 16px 8px;
}


.uk-table.uk-table-hover tbody{
  tr{
    transition: background-color 0.75s ease;
  }
  tr:hover{
    background-color:$lilladark;
    transition: background-color 0.5s ease;
    td{
      background-color:$lilladark;
      transition: background-color 0.75 ease;
    }
  }

}
/* prices in tables - prices in regular table*/
.uk-table{
  .price-number{
    font-size: 1rem;
    @media screen and (min-width:$breakpoint-m){
      font-size: 1.25rem;
    }
    @media screen and (min-width:$breakpoint-m){
      font-size: 1.25rem;
    }
  }
}


/* 2A COMMON PROPERTIES - DOMAINS + SSL TABLE --- HEIGHT + PROMO BADGE TABLE  + pricing */
.table-domains,
.table-ssl{
  width:100%;
  @media screen and (min-width: $breakpoint-s){
    width:75vw;
    margin:0 auto;
  }
  @media screen and (min-width: $breakpoint-m){
    width:100%;
  }
  .uk-label{
    pointer-events:none;
  }
  @media screen and (min-width: $breakpoint-m){
    th{
      background:$light;
      position: sticky;
      top: 118px; /* Don't forget this, required for the stickiness */
      //box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
      z-index:100;
    }
  }
  tbody > tr:not(.extra) td{
   
    @media screen and (min-width: $breakpoint-m){
      height:100px;
     
    }
    box-sizing: border-box;
  }
  th.promo{
    width:26px;
  }
  td{
    position: relative;
    span.badge-promo{
      background:$yellow;
      color:$darkblue;
      font-weight:bold;
      font-size:1.25rem;
      text-transform: uppercase;
      display:block;
      //height:100px;
     
      text-align: center;
      line-height: 1.2;
      padding: 0.5rem 0;
     
      @media screen and (min-width: $breakpoint-m){
        writing-mode: vertical-lr; 
        transform: rotate(180deg);
        width:26px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        line-height: 26px;
        font-size:0.875rem;
        padding:0;
      }
    }
  }
  td{
    
    div.price + div.price-old{
      margin-top:0.5rem;
      @media screen and (min-width: $breakpoint-m){
        margin-top:0.25rem;
      }
    }
    div.price-old .price-number{
      font-size:0.875rem;
    }
    div.price{
      //padding:2em 0 0 0;
      @media screen and (min-width: $breakpoint-m){
        padding:0;
      }
    }
    
  }

  td:not(.domain){
    line-height: 18px;
  }
  div.price .price-number{
    font-size:1.875rem;
    white-space: nowrap;
     @media screen and (min-width:$breakpoint-m){
       font-size:1.25rem;
    } 
    @media screen and (min-width:$breakpoint-l){
       font-size:1.5rem;
    } 
  }

  
}
/*
2. domain name table with divs
*/
div.table-domains{
  div{
    box-sizing: border-box;
  }
  .table-body .table-row,
  >:not(:first-child)>div{
    border-top: 1px solid $bordergrey;
    border-left: 1px solid $bordergrey;
    border-right: 1px solid $bordergrey;
    border-bottom: 1px solid $bordergrey;
    border-radius: 20px;
    /*padding: 1rem;*/
    padding: 0;
    margin-bottom: 1.25rem;
    @media screen and (min-width: $breakpoint-m){
      border-top: 1px solid $bordergrey;
      border-left:0;
      border-right:0;
      border-bottom:0;
      border-radius: 0;
      padding: 0;
      margin-bottom:0;
    }
  }
  .table-header{
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .table-row{
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
  }
  /*.table-row:nth-of-type(2n+1){
    background:#f8f8f8;
  }

  .table-row:hover{
     background-color: $lilladark;
     transition: background-color 0.5s ease;
   }*/
  div:nth-of-type(2n+1) .table-row{
    background:#f8f8f8;
    &:hover{
      background-color: $lilladark;
      transition: background-color 0.5s ease;
    }
  }

  .promo{
    width:26px;
  }
  .promo{
    position: relative;
    span.badge-promo{
      background:$yellow;
      color:$darkblue;
      font-weight:bold;
      font-size:1.25rem;
      text-transform: uppercase;
      display:block;
      //height:100px;

      text-align: center;
      line-height: 1.2;
      padding: 0.5rem 0;

      @media screen and (min-width: $breakpoint-m){
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        width:26px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        line-height: 26px;
        font-size:0.875rem;
        padding:0;
      }
    }
  }
  .domain{
    width: 300px;
  }
  .table-body .domain{
    align-self: center;
    font-size:1.5rem;
    line-height:1.2;
    @media screen and (min-width: $breakpoint-m){
      font-size:1rem;
      //min-width:200px;
    }

    @media screen and (min-width: $breakpoint-l){
      font-size:1.25rem;
      //font-weight: bold;
      line-height: 20px;
      //min-width: 350px;
    }
    
  }
  .domain-name{
    font-weight:normal;
    display:block;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    @media screen and (min-width: $breakpoint-m){
      max-width: 200px;
    }
    @media screen and (min-width: $breakpoint-l){
      max-width: 400px;
    }
    
  }
  .domain-ext,
  .domain-ext a{
    color:$darkblue;
    font-weight: bold;
    &:hover{
      color:$linkcolor;
      text-decoration: none;
    }
  }
  .availabity{
    align-self: center;
    width:30px;
  }
  .period{
    padding:0;
    width: 275px;
    align-self: center;
    .uk-inline{
      width:100%;
      padding:0;
      @media screen and (min-width: $breakpoint-m){
        width:100%;
      }
    }
    .uk-inline .uk-button-outlined{
      width:100%;
      text-align: left;
      display:flex;
      //justify-content: space-between;
      align-items:center;
      i{
        position: absolute;
        right: 1rem;
      }
      @media screen and (min-width: $breakpoint-m){
        width:auto;
        display:block;
        i{
          position: relative;
          right: 0;
        }
      }
    }
    .uk-button-outlined{
      border-color: $bordergrey;
      font-weight: normal;
      color: $darkgrey;
      font-size:1rem;
      padding: 0 10px 0 20px;
      @media screen and (min-width: $breakpoint-m){
        padding: 0 10px 0 20px;
        font-size:0.875rem;
      }
      @media screen and (min-width: $breakpoint-l){
        padding: 0 10px 0 20px;
        font-size:0.875rem;
      }
      &:hover{
        color:$text-inverse;
        border-color: $darkblue;
      }
    }
    .uk-label{
      margin:0 0.5em;
    }
    .uk-dropdown .uk-list a:hover{
      text-decoration: none;
    }
    .uk-dropdown > *:last-child{
      margin-bottom:0;
    }
    .uk-dropdown{
      font-size:0.875rem;
      min-width: 250px;
      padding: 1rem;
    }
  }
  .price-outer{
    align-self: center;
    width:225px;
  }
  div.price{
    align-self: center;
    padding:1em 0 0 0;
     @media screen and (min-width: $breakpoint-m){
        padding:0;
     }
  }
  /*
  td.price{
    font-size:1.5rem;
    font-weight: bold;
    color:$darkblue;
  }
  */

  .uk-label:hover{
    color:$darkblue;
    background:$lilladark;
  }
  .actions{
    align-self: center;
    width:175px;
     text-align: center;
     span{
      display:block;
      text-align: center;
     }
      a.remove{
        font-size:0.875rem;
        text-decoration: underline;
      }
  }
  .table-body .selected{
    background-color:$lilladark;
    transition: background-color 0.5s ease;
  }
  .table-body .promo{
    //background-color:lighten($yellow,35%);
    transition: background-color 0.5s ease;
  }
  div p{
    margin:0;
  }
  div.table-row > div{
    padding:0.5rem 1rem ;
    @media screen and (min-width: $breakpoint-m){
      padding:0.5rem;
    }
  }
  /*td.actions .uk-button{
    margin-top:1.5rem !important;
    margin-bottom:1.5rem !important;
    @media screen and (min-width: $breakpoint-m){
      margin:0;
    }
  }*/
  .actions,
  .actions:not(:first-child):not(.uk-table-link){
    padding-top:1.5rem !important;
    padding-bottom:1.5rem !important;
    @media screen and (min-width: $breakpoint-m){
      padding:0;
    }
  }
  
}
@media screen and (max-width:$breakpoint-s){
  div.table-domains {
    .table-row {
      flex-direction: column;
      > div {
        width: 100%;
        text-align: left;
        padding:0.5rem;
      }
      span.badge-promo{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      .price{
        padding:0 0.5rem;
      }
      .actions{
        text-align: center;
      }
    }
  }
}
@media screen and (max-width: $breakpoint-small-max){
  div.table-domains{
    .table-header{
      display:none;
    }
    .table-row{
      flex-direction: column;
      > div{
        width:100%;
        text-align: left;
        padding:1rem;
      }
      span.badge-promo{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      .price{
        padding:0 0.5rem;
      }
      .actions{
        text-align: center;
      }
    }
  }
}






/*
3. SSL table
 */
.table-ssl{
  
  th.brand{
    width:175px;
  }
  th.info{
    width:36px;
  }
  th.type{}
  th.time{}
  th.domains{}
  th.price{}
  th.btn{}
  img.logo{
      margin:0 auto;
      display:block;
      max-width:65vw;
    @media screen and (min-width: $breakpoint-m){
      max-width:150px;
      max-height: 35px;
      margin:0;
      display:inline;
    }
  }
   tbody tr,
  >:not(:first-child)>tr{
    border-top: 1px solid $bordergrey;
    border-left: 1px solid $bordergrey;
    border-right: 1px solid $bordergrey;
    border-bottom: 1px solid $bordergrey;
    border-radius: 20px;
    padding: 1rem;
    margin-bottom: 1.25rem;
    @media screen and (min-width: $breakpoint-m){
      border-top: 1px solid $bordergrey;
      border-left:0;
      border-right:0;
      border-bottom:0;
      border-radius: 0;
      padding: 0;
      margin-bottom:0;
    }
  }

  td{
    font-size:0.75rem;
    //line-height: 18px;
    vertical-align: middle;
    //height: 100px;
    //padding: 0;
    position: relative;
    @media screen and (min-width: $breakpoint-m){

    }
    @media screen and (min-width: $breakpoint-xl){
      font-size:0.875rem;
    }
  }
  td.type{
    a{
      text-decoration: underline;
    }
  }
  tr > td:last-child{
    text-align:right;
  }
  tr.extra{
    border-top: 1px solid transparent;
    transition:all 0.5s ease-out;
    td,
    td:last-child{
      padding:0;
      text-align:left;
    }
    strong{
      display:block;
    }
  }
  tr:last-child{
    border-bottom:1px solid $lillalight;
  }
  .extra-info{
    padding:40px 0;
    display: flex;
    justify-content: center;
  }
  .extra-info ul{
    margin:0;
    max-width:750px;
    display:flex;
    justify-content: space-evenly;
    li{
      margin:0;
      padding:0 1em;
    }
    li+li{
      border-left:1px solid $darkgrey;
    }
  }
  
}

/* 
4. table in cards 
*/
.uk-card{
  table{
    width:100%;
    td{
      width:50%;
      color:$darkgrey;
      padding:.25em 0;
      border-bottom:1px solid $bordergrey;
    }
    td:last-child{
      padding-left:0.5em;
    }
    tr:last-child td{
       border-bottom:1px solid transparent;
    }
  }
}

/*
5. table extentions - prices promo
*/
.table-ext{
  .price-promo .price-number{
    font-size:1rem;
    margin-left:0;
    &:before{
      background:none;
    }
  }
  tbody{ 
    td{
      height:64px;
      box-sizing: border-box;
      vertical-align: middle;
    }
    td.promo{
      width:35px;
      position:relative;
    }
    span.badge-promo {
      -webkit-writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
      writing-mode: vertical-lr;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      width: 26px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background: $yellow;
      color: $darkblue;
      font-weight: bold;
      font-size: 0.875rem;
      text-transform: uppercase;
      display: block;
      text-align: center;
      line-height: 26px;
    }
  }
  .uk-label{
    pointer-events:none;
  }

}
/*
6. large comparison table for products - prices - buttons - active class down below
------------------------------------------------------------------
 */
.table-comparison{
  width:100%;
  display:table !important;
  background:$text-inverse;
  th, td {
    padding: 16px 16px;
    @media screen and (min-width:$breakpoint-l){
      padding: 16px 24px;
    }

  }
  tbody td,
  tbody th{
    border-right:1px solid $bordergrey;
  }
  thead th,
  tfoot td{
    background:$text-inverse;
  }

  //thead th:first-child,
  //thead th:last-child,
  //td:first-child,
  //tfoot td:first-child,
  tbody td:last-child
  {
    border-right:1px solid $text-inverse;
  }
  thead th img{
    max-width:64px;
    max-height:64px;
  }
  thead th h3,
  thead th h4{
    font-weight:700;
    margin:0.5rem 0;
    width: max-content;
  }
  thead th a:hover,
  thead th a:active,
  thead th a:focus{
    text-decoration:none;
    /*display: inline;
    background-image: linear-gradient(#0bcfde, #0bcfde);
    background-position: 0% 98%;
    background-repeat: no-repeat;
    background-size: 100% clamp(10px, 2vw, 10px);
    transition: background-size 0.3s;*/
  }
  thead,
  tbody,
  thead tr,
  tbody tr{
    width:100%;
  }
  thead th{
    text-align: center;
  }
  tbody th{
    text-transform: none;
    text-align:left;
  }
  tbody tr td{
    text-align:center;
  }
  /*tbody tr td:first-child{
    text-align:left;
  }*/
  tbody tr:first-child{
    border-top:0 solid transparent;
  }
  tbody tr:first-child td{
    text-align: left;
    background:$lillalight;
    color:$darkblue;
    text-transform: uppercase;
    font-weight: 700;
  }
  /*@media screen and (min-width:$breakpoint-l) {
    thead th{
      position: sticky;
      top: 118px;
    }
    tbody tr:first-child,
    tfoot tr:first-child{
      position: sticky;
      top: 300px;
    }

  }*/
  thead th{
    position: sticky;
    top: 118px;
  }
  tbody tr:first-child,
  tfoot tr:first-child{
    /*
    position: sticky;
    top: 410px;
    */
  }
  tbody tr th:last-child{
    border-right:1px solid transparent;
  }
  /* PRICES */
  .price-from,
  .price-number,
  .price-duration{
    display:block;
    text-align: center;
  }
  .uk-dropdown .price-from,
  .uk-dropdown .price-number,
  .uk-dropdown .price-duration{
    display:inline !important;
    text-align: left !important;
  }

  button[type=submit],
  .uk-button,
  .uk-button-default{
    display:block;
    margin:0 auto;
  }
}
/* ACTIVE PRODUCT IN TABLE */
.table-comparison th.active,
.table-comparison td.active{
  background:$lightestgrey;
}

/* PRICES DOMAINS - SSL TABLES */
#products-table{
  .uk-table{
    /* PRICES */
    td > .price-from,
    td > div > .price-from,
    td > .price-number,
    td > div > .price-number,
    td > .price-duration,
    td > div > .price-duration{
      display:inline-block;
      text-align: left;
      @media screen and (min-width: $breakpoint-m) {
        display: block;
        text-align: center;
      }
    }
    .uk-text-center{
      text-align: left !important;
      @media screen and (min-width: $breakpoint-m) {
        text-align: center !important;
      }
    }
  }
}