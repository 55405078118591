/* STICKY */
.uk-navbar-sticky{
	position:sticky !important;
	@media screen and (min-width:$breakpoint-l){
		padding-top: 0;
	}
	-webkit-transition: background-color 0 ease;
	-o-transition: background-color 0 ease;
	transition: background-color 0 ease;
	background-color:$light;
}
.subnav-sticky-fw.uk-sticky-fixed,
.subnav-sticky-fw .uk-sticky-fixed{
   z-index: 900;
   border-bottom: 2px solid $lilladark;
}

/* TABS */
#subnav{
	.uk-tab{
		position:relative;
		top:2px;
		justify-content: center;
	}
	.uk-tab::before {
	    content: "";
	    position: absolute;
	    bottom: 0;
	    left: 20px;
	    right: 0;
	    border-bottom: 2px solid $lilladark;
	}

	.uk-tab > .uk-active > a {
    color: $darkblue;
    /*border-color: $darkblue;*/
    border-bottom-color: transparent;
    &:after{
    	content: "";
	    position: absolute;
	    bottom: -2px;
	    left: 0;
	    height:2px;
	    background:$darkblue;
	    width:100%;
    }
	}
	.uk-tab>* {
    padding-left: 0.25rem;
    @media screen and (min-width: $breakpoint-m){
    	padding-left:1.25rem;
    }

	}
	.uk-tab > * > a {
		position:relative;
		font-weight:700;
		color:$darkblue;
		 font-size: 1rem;
    display: block;
    text-align: center;
    padding: 5px 10px;
    color: $darkblue;
    border-bottom: 2px solid transparent;
    border-bottom-color: transparent;
    text-transform: uppercase;
    transition: color .1s ease-in-out;
    &:hover{
    	color:$darkblue;
    	/*border-color: $linkcolor;*/
    	border-bottom-color: transparent;
    }
    &:after{
    	content: "";
	    position: absolute;
	    bottom: -2px;
	    left: 0;
	    width:0;
	    height:2px;
	    background:$darkblue;
	    transition: width .2s ease-in-out;
    }
    &:hover:after{
    	width:100%;
    	transition: width .2s ease-in-out;
    }
	}
}
/* MOBILE MARGIN FOR price */
.uk-position-relative:has(.pricebox) + .subnav-sticky-fw{
	margin-top:130px !important;
	@media screen and (min-width:$breakpoint-m){
		margin-top:80px !important;
	}
	@media screen and (min-width:$breakpoint-l){
		margin-top:20px !important;
	}
}