@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./Open-Sans-Regular.eot'); /* IE9 */
  src: url('./Open-Sans-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./Open-Sans-Regular.woff2') format('woff2'), /* Modern Browsers */
    url('./Open-Sans-Regular.woff') format('woff'), /* Modern Browsers */
    url('./Open-Sans-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./Open-Sans-Regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/*@font-face {*/
/*  font-family: 'Open Sans';*/
/*  font-style: normal;*/
/*  font-weight: 300;*/
/*  font-display: swap;*/
/*  src: url('./Open-Sans-300.eot'); !* IE9 *!*/
/*  src: url('./Open-Sans-300.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!*/
/*  url('./Open-Sans-300.woff2') format('woff2'), !* Modern Browsers *!*/
/*  url('./Open-Sans-300.woff') format('woff'), !* Modern Browsers *!*/
/*  url('./Open-Sans-300.ttf') format('truetype'), !* Safari, Android, iOS *!*/
/*  url('./Open-Sans-300.svg#OpenSans') format('svg'); !* Legacy iOS *!*/
/*}*/
/*@font-face {*/
/*  font-family: 'Open Sans';*/
/*  font-style: normal;*/
/*  font-weight: 700;*/
/*  font-display: swap;*/
/*  src: url('./Open-Sans-700.eot'); !* IE9 *!*/
/*  src: url('./Open-Sans-700.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!*/
/*  url('./Open-Sans-700.woff2') format('woff2'), !* Modern Browsers *!*/
/*  url('./Open-Sans-700.woff') format('woff'), !* Modern Browsers *!*/
/*  url('./Open-Sans-700.ttf') format('truetype'), !* Safari, Android, iOS *!*/
/*  url('./Open-Sans-700.svg#OpenSans') format('svg'); !* Legacy iOS *!*/
/*}*/
/*@font-face {*/
/*  font-family: 'Open Sans';*/
/*  font-style: italic;*/
/*  font-weight: 300;*/
/*  font-display: swap;*/
/*  src: url('./Open-Sans-300italic.eot'); !* IE9 *!*/
/*  src: url('./Open-Sans-300italic.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!*/
/*  url('./Open-Sans-300italic.woff2') format('woff2'), !* Modern Browsers *!*/
/*  url('./Open-Sans-300italic.woff') format('woff'), !* Modern Browsers *!*/
/*  url('./Open-Sans-300italic.ttf') format('truetype'), !* Safari, Android, iOS *!*/
/*  url('./Open-Sans-300italic.svg#OpenSans') format('svg'); !* Legacy iOS *!*/
/*}*/
/*@font-face {*/
/*  font-family: 'Open Sans';*/
/*  font-style: italic;*/
/*  font-weight: 400;*/
/*  font-display: swap;*/
/*  src: url('./Open-Sans-Italic.eot'); !* IE9 *!*/
/*  src: url('./Open-Sans-Italic.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!*/
/*  url('./Open-Sans-Italic.woff2') format('woff2'), !* Modern Browsers *!*/
/*  url('./Open-Sans-Italic.woff') format('woff'), !* Modern Browsers *!*/
/*  url('./Open-Sans-Italic.ttf') format('truetype'), !* Safari, Android, iOS *!*/
/*  url('./Open-Sans-Italic.svg#OpenSans') format('svg'); !* Legacy iOS *!*/
/*}*/
/*@font-face {*/
/*  font-family: 'Open Sans';*/
/*  font-style: italic;*/
/*  font-weight: 700;*/
/*  font-display: swap;*/
/*  src: url('./Open-Sans-700italic.eot'); !* IE9 *!*/
/*  src: url('./Open-Sans-700italic.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!*/
/*  url('./Open-Sans-700italic.woff2') format('woff2'), !* Modern Browsers *!*/
/*  url('./Open-Sans-700italic.woff') format('woff'), !* Modern Browsers *!*/
/*  url('./Open-Sans-700italic.ttf') format('truetype'), !* Safari, Android, iOS *!*/
/*  url('./Open-Sans-700italic.svg#OpenSans') format('svg'); !* Legacy iOS *!*/
/*}*/
