/*.grid-masonry{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(580px, 1fr));
  grid-gap: 40px;
  grid-auto-rows: minmax(180px, auto);
  grid-auto-flow: dense;
  align-content: start;
  align-items: start;
}*/
.grid-masonry{
  display:flex;
  .card-case{
    margin-bottom:40px;
    max-width:580px !important;
  }
  .card-square{
    order:1;
  }
  .card-hor:first-of-type{
    order:2;
  }
  .card-hor:last-of-type{
    order:3;
  }
  .card-vert{
    order:4;
  }
}


.grid-masonry-2{
  display:flex;
  .grid-sizer,
  .card-case{
    width:100%;
  }
  .gutter-sizer{
    width:0%;
  }
  .card-case{
    margin-bottom:40px;
    /*max-width:373.33px !important;*/
    /*width:calc(33.33% - 20px);*/
  }
  .card-square{
    order:1;
  }
  .card-hor:first-of-type{
    order:2;
  }
  .card-hor:last-of-type{
    order:3;
  }
  .card-vert{
    order:4;
  }

  .card-case{
    .uk-card-body{
      padding: 15px 15px;
    }
    .uk-card-footer{
      padding: 10px 15px 20px 15px;
    }
    h3,
    .h3,
    .uk-card-title{
      margin:0;
      font-size:1.5rem;

    }
    p{
      font-size:1.25rem;
      margin:0 !important;
    }
  }
  @media (min-width: $breakpoint-s){
    .grid-sizer,
    .card-case{
      width:49%;
    }
    .gutter-sizer{
      width:2%;
    }
  }
  @media (min-width: $breakpoint-l){
    .grid-sizer,
    .card-case{
      width:31.11333333333333%;
    }
    .gutter-sizer{
      width:3.33%;
    }
  }
}