.card-testimonial{
  color:$darkgrey;
  .uk-card-body {
    padding: 40px 20px;
    p{
      position:relative;
    }
    .quote-bottom{
      float: right;
    }
  }
  .uk-card-footer {
    border-top: 1px solid transparent;
    padding: 0 20px 30px 20px;
    .uk-card-title {
      margin: 0 0 0.5rem 0;
    }
    p{
      font-style: italic;
    }
  }

}
.uk-grid > div:nth-child(even) > .card-testimonial > .uk-card-body{
  svg.quote-top path,
  svg.quote-bottom path{
    fill: $darkblue;
  }
  p{
    color:$darkblue;
  }
}

.uk-grid > div:nth-child(odd) > .card-testimonial > .uk-card-body{
  svg.quote-top path,
  svg.quote-bottom path{
    fill: $yellow;
  }
  p{
    color:$darkgrey;
  }
}

/* TESTIMONIALS SLIDER */
.testimonial-slider{
  .uk-slider-container{
    border-radius:$border-radius;
    border:1px solid $bordergrey;
  }
  .testimonial-item{
    display:flex;
    flex-direction: column; 
    padding:40px;
    @media screen and (min-width: $breakpoint-s){
      flex-direction: row; 
      padding:50px 40px;
    }
  }
  .testimonial-image{
    flex-basis: content;
    flex-grow: 0;
    min-width:250px;
    img{
      width:150px;
      height:150px;
      border-radius:75px;
      display: block;
      margin: 0 auto;
    }
    figcaption {
      margin:1em 0;
      text-align: center;
      font-style: italic;
      span{
        display: block;
        font-weight: bold;
        color:$darkblue;
         font-style: normal;
      }
    }
  }
  .testimonial-text{
    flex-grow: 1;
    display:flex;
    align-items:center;
    justify-content: center;
    blockquote{
      font-style: normal;
      display: inline;
      position:relative;
      font-size:1rem;
      line-height: 1.8;
      position: relative;
      max-width: 750px;
      &:before{
        position:absolute;
        width:40px;
        height:27px;
        //content:url('data:image/svg+xml;charset=UTF-8, <svg version="1.1" class="quote-top" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="27px" viewBox="0 0 40 27" enable-background="new 0 0 40 27" xml:space="preserve"><path d="M10.982,11.152c3.375,1.027,5.723,4.109,5.723,7.777c0,4.402-3.668,8.07-8.218,8.07c-4.402,0-8.07-3.668-8.07-8.07 c0-2.201,0.88-4.109,1.614-5.577L9.074,0h4.695L10.982,11.152z M33.58,11.152c3.375,1.027,5.723,4.109,5.723,7.777 c0,4.402-3.668,8.07-8.218,8.07c-4.401,0-8.07-3.668-8.07-8.07c0-2.201,0.881-4.109,1.614-5.577L31.672,0h4.695L33.58,11.152z"/>');
        left:0;
        top:-44px;
        content:url(../img/svg/quote-top.svg);

      }
      &:after{
        position:absolute;
        width:40px;
        height:27px;
        //content:url('data:image/svg+xml;charset=UTF-8, <svg version="1.1" class="quote-top" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="27px" viewBox="0 0 40 27" enable-background="new 0 0 40 27" xml:space="preserve"><path d="M10.982,11.152c3.375,1.027,5.723,4.109,5.723,7.777c0,4.402-3.668,8.07-8.218,8.07c-4.402,0-8.07-3.668-8.07-8.07 c0-2.201,0.88-4.109,1.614-5.577L9.074,0h4.695L10.982,11.152z M33.58,11.152c3.375,1.027,5.723,4.109,5.723,7.777 c0,4.402-3.668,8.07-8.218,8.07c-4.401,0-8.07-3.668-8.07-8.07c0-2.201,0.881-4.109,1.614-5.577L31.672,0h4.695L33.58,11.152z"/>');
        right:0;
        bottom:-44px;
        content:url(../img/svg/quote-bottom.svg);

      }
    } 
    .quote-top,
    .quote-bottom{
      display:block;
    }
  }
}

/* QUOTES OF testimonials slider */
.uk-slider li:nth-child(even){
   blockquote{
    &:before{
      content:url(../img/svg/quote-top-blue.svg);
    }
    &:after{
      content:url(../img/svg/quote-bottom-blue.svg);
    }
   }
}