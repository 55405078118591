.topnav{
  background:$lightbg1;
  height:40px;
  position: relative;
  z-index:50;
  color:$darkblue;
  .uk-navbar-small .uk-navbar-nav > li > a {
  	color:$darkblue;
  	text-decoration: none;
    text-transform:uppercase;
    font-weight: 600;
    &:hover,
    &:active,
    &:focus{
      text-decoration: underline;
    }

  }
  .uk-navbar-nav > li{
    position:relative;
  }
  .uk-navbar-nav > li + li:before{
    content:'';
    position: absolute;
    top:12px;
    left:0;
    width:1px;
    height:16px;
    background:$darkblue;

  }
  .uk-navbar-small .uk-navbar-nav.langsel > li > a{
    text-decoration: none;
    text-transform:none;
    &:hover,
    &:active,
    &:focus{
      text-decoration: none;
    }
  }
  .uk-navbar-small .uk-navbar-item, 
  .uk-navbar-small .uk-navbar-nav > li > a, 
  .uk-navbar-small .uk-navbar-toggle {
    min-height: 40px;
	}
}
.ki-top-navigation{
  .uk-navbar-item,
  .uk-navbar-nav > li > a,
  .uk-navbar-toggle,
  .uk-button.uk-button-text {
    font-size: .75rem;
    @media screen and (min-width: $breakpoint-s){
      font-size: .875rem;
    }
  }
}