.well{
	background:$light;
	padding:1.875rem;
	box-shadow: $boxshadow-diffused;
	border-radius:1.25rem;
	/*margin-top:3.125rem‬;
	margin-bottom:3.125rem‬;*/
	display: flex;
	flex-direction: column;
	text-align: center;
	@media screen and (min-width:$breakpoint-s){
		flex-direction: row;
		justify-content: space-between;
		text-align: left;
	}
	
	max-width:750px;
	margin:0 auto;
	p{
		margin:0;
		font-weight:700;
		line-height: 40px;
	}
}
.well-services{
	position:relative;
	z-index:100;
	border:2px solid $cardborder;
	max-width: auto !important;
  width: auto !important;

  box-sizing: border-box;
  margin-top:3rem;
  margin-bottom:3rem;

  flex-direction: column;

  @media screen and (min-width:$breakpoint-s){
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	@media screen and (min-width:$breakpoint-m){
		max-width: 100% !important;
  	width: 100% !important;
	}

	@media screen and (min-width:$breakpoint-l){
		flex-wrap: nowrap;
	}

	.well-item{
		width:100%;
		@media screen and (min-width:$breakpoint-s){
			width:45%;
		}
		@media screen and (min-width:$breakpoint-m){
			width:20%;
		}

		
		text-align: center;
		p{
			font-weight:400;
			font-size:1rem;
			line-height:1.4;
			margin:1rem 0 2rem 0 ;
			@media screen and (min-width:$breakpoint-l){
				margin:2rem 0 0 0;
			}
		}
	}
	.well-item img{
		max-width:130px;
		max-height:130px;
		display:block;
		margin:0 auto;
	}
}
.well-transparent{
	background:rgba($light,0.5);
	box-shadow:$buttonshadow;
	border-radius:0.625rem;
}