@import './fonts/opensans/font.css';
@import './fonts/opensans/fallbacks.css';
@import "~uikit/src/scss/variables-theme.scss";
@import "~uikit/src/scss/mixins-theme.scss";
@import "~uikit/src/scss/uikit.scss";
@import "./toastr";
@import './styles.scss';
@import './peter.scss';
@import './freeform-v2.scss';
@import './htmx.scss';
@import 'b-named'; // CSS for new domain search

@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
@import '~@fortawesome/fontawesome-pro/scss/regular';
@import '~@fortawesome/fontawesome-pro/scss/brands';
//@import '~flag-icons/css/flag-icons.css';
//@import '~animate.css/animate.min.css';