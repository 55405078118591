/*
1. skew properties
2A. Main header text + buttons
2B. Small header text
3. header illustration
3A. Header domains
3B. Header domains worldmap  on single page domainname
4. Responsive
*/
/*
FOR DEVELOPMENT HEADER see _development-pages.scss
 */
/*
1. skew properties
*/
.ki-header--skewed{
  overflow: visible !important;
  position: relative;
  background-image:
          -webkit-gradient(
                          linear,
                          left top, right top,
                          from($gradientcolor1),
                          color-stop($gradientcolor2),
                          to($gradientcolor3)
          );
  background-image:
          -webkit-linear-gradient(
                          91deg,
                          $gradientcolor1,
                          $gradientcolor2,
                          $gradientcolor3
          );
  background-image:
          -o-linear-gradient(
                          91deg,
                          $gradientcolor1,
                          $gradientcolor2,
                          $gradientcolor3
          );
  background-image:
          linear-gradient(
                          91deg,
                          $gradientcolor1,
                          $gradientcolor2,
                          $gradientcolor3
          );
  /*padding: 200px 0 0 0;*/
  @media screen and (min-width:$breakpoint-m){
    overflow:hidden;
    height: 400px;
  }
  @media screen and (min-width:$breakpoint-l){
    overflow:hidden;
    height: 600px;
    -webkit-transform: skewY(-6deg);
    -ms-transform: skewY(-6deg);
    transform: skewY(-6deg);

    -webkit-transform-origin:left top;
    -ms-transform-origin:left top;
    transform-origin:left top;
    -webkit-border-bottom-left-radius:150px;
    border-bottom-left-radius:150px;
    -webkit-border-bottom-right-radius:150px;
    border-bottom-right-radius:150px;
  }

  .content{
    padding:100px 0 50px 0;
    @media screen and (min-width:$breakpoint-l){
      -webkit-transform: skewY(6deg);
      -ms-transform: skewY(6deg);
      transform: skewY(6deg);
      text-align: center;
      padding:200px 0 0 0;
    }

    .uk-container{
      position:relative;
    }

  }
}
.bg-rotate01{
  display:none;
  @media screen and (min-width:$breakpoint-l){
    display:block;
    width:100%;
    height:850px;
    position:absolute;
    top:0;
    left:0;
    -webkit-transform: skewY(6deg);
    -ms-transform: skewY(6deg);
    transform: skewY(6deg);
    background:url(../img/header-bg-rotation.png);
    background-repeat:no-repeat;
    -webkit-background-size:cover;
    background-size:cover;
    background-position:top left;
  }

}

.ki-header-small{
  height: auto;
  margin-bottom:2rem;
  @media screen and (min-width:$breakpoint-m){
    overflow:hidden;
    height: auto;
  }
  @media screen and (min-width:$breakpoint-l){
    overflow:hidden;
    height: 400px;

  }

  .content{
    padding:100px 0 50px 0;

    @media screen and (min-width:$breakpoint-l){
      -webkit-transform: skewY(6deg);
      -ms-transform: skewY(6deg);
      transform: skewY(6deg);
      text-align: center;
      padding:175px 0 750px 0;
    }
    @media screen and (min-width:$breakpoint-xl){
      -webkit-transform: skewY(6deg);
      -ms-transform: skewY(6deg);
      transform: skewY(6deg);
      text-align: center;
      padding:225px 0 75px 0;
    }
  }
}
/*
2A. Main header text + buttons
*/
.ki-header h1{
  color:$yellow;
  font-weight:700;
  margin:0 0 5px 0;
  strong{
    color:$text-inverse;
  }
}
.ki-header h2{
  color:$text-inverse;
  font-weight: 300;
  margin:0 0 20px 0;
  strong{
    color:$yellow;
    font-weight: 300;
  }
}

.ki-header .uk-grid .uk-first-column{
  @media screen and (min-width:$breakpoint-m){
    height:200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media screen and (min-width:$breakpoint-l){
    height:300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.ki-header .uk-button + .uk-button{
  margin:1rem 0 1rem 1rem;
  @media screen and (min-width:$breakpoint-s){
    margin:0 0 0 1rem;
  }
}
/*
2B. Small header text
*/
.ki-header-small h1{
  /*font-size: 2.5rem;*/
  margin:0 0 5px 0;
}
.ki-header-small h2{
  /*font-size: 2rem;*/
  margin:0 0 5px 0;
}

.ki-header-small h3{
  /*font-size: 1.75rem;*/
}
.ki-header-small h1{
  color:$yellow;
  font-weight:700;
}
.ki-header-small h2{
  color:$text-inverse;
  font-weight: 300;
}
/*
3. Header illustration
*/
.header-illustration{
  display:none;
  @media screen and (min-width:$breakpoint-m){
    display:block;
    position:absolute;
    top:-170px;
    right:0;
    transform:scale(0.75) translate(150px, 0);
    /* problem in edge with the left positioning, cuts of text above */
    /*left: 50px;*/
    svg{
      position:relative;
      /*left:50px;*/
    }
  }
  @media screen and (min-width:$breakpoint-l){
    display:block;
    position:absolute;
    top:-160px;
    right:0;
    transform:scale(0.75) translate(200px, 0);
    /* problem in edge with the left positioning, cuts of text above */
    /*left: 50px;*/
    svg{
      position:relative;
      /*left:50px;*/
    }
  }
}
/*
.header-illustration.header-cloud,
.header-illustration.header-support{

top:-130px;

  svg{
    position:relative;
    
  }
}
*/

/* 3A. Header domains */
.header-illustration.header-domain-search{
  display:none;
  @media screen and (min-width:$breakpoint-m){
    display: block;
    position: absolute;
    top: -50px;
    right: 0;
    transform: scale(1) translate(0);
  }
  @media screen and (min-width:$breakpoint-l){
    display: block;
    position: absolute;
    top: -50px;
    right: 0;
    transform: scale(1.5) translate(-100px);
  }
}
/*
#header-domains,
.header-domain-search img,
.header-domain-search svg{
  display:none;
  position: relative;
  @media screen and (min-width:$breakpoint-m){
    width: 50vw;
    top: -75px;
  }
  @media screen and (min-width: $breakpoint-l){
    width: 50vw;
    top: -75px;
  }

}
*/

.header-domain-search{
  h1{
    color: $text-inverse;
    font-weight: normal;
    max-width: 750px;
    strong{
      font-weight: bold;
      color:$yellow;
    }

  }
  h1:has(p){
    line-height: 1.2;
    margin-bottom: 20px;
    @media screen and (min-width: $breakpoint-l){
      margin-bottom:0;
    }
  }
  h2:has(p){
    line-height: 1.2;
    margin-top:0;
  }
}


.ki-header .well{
  width:100%;
  max-width:100%;
  //margin:6vh 0;
  margin: 2vh 0;
  box-sizing: border-box;
}
/* 3B. Header domains worldmap  on single page domainname */
.header-illustration{
  #worldmap{
    display:none;
    @media screen and (min-width:$breakpoint-m){
      display: block;
      top:-125px;
    }
    @media screen and (min-width:$breakpoint-l){
      display: block;
      top:-75px;
    }
  }
}

body .ki-header .tag-list,
body .ki-header-small .tag-list
{
  list-style-type: none;
  margin:1rem 0;
  padding:0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  li{
    color:$yellow;
    margin-right:2rem;
    font-size: 1rem;
    font-weight: 600;
    position:relative;
    text-transform: lowercase;
    &:after{
      position:absolute;
      display:block;
      width:8px;
      height:8px;
      top:calc(50% - 2px);
      right:calc(1rem - 25px);
    }
  }
}