.uk-navbar-dropdown,
.uk-dropdown{
  width: 250px;
  box-shadow:$boxshadow;
  border-radius:$border-radius;
}

.uk-navbar-dropdown-nav{
	>li>a {
   color: $mediumgrey;
   transition:all 0.25s ease;
   &:hover,&:active,&:focus{
   	color:$darkblue;
   	transition:all 0.25s ease;
   }
	}
	>li.uk-active>a{
		color:$darkblue;
	}
	.uk-nav-header{
		color: $darkgrey;
		font-weight:700;
	}	
}

/* DROPDOWN LANGUAGE - WIDTH AUTO */
li.lang{
	.uk-navbar-dropdown, 
	.uk-dropdown {
		width:auto;
		ul > li > a {
    	white-space: nowrap;
		}
	}
}

/* STYLING LIST IN DROPDOWN - USED IN SECTION-CTA */
.uk-dropdown > *:last-child{
	margin-bottom: 0;
}
.uk-dropdown .uk-list{
	a{
		position:relative;
		color:$listlinkcolor;
		padding-left: 16px;
	}
	a:before{
		font-family: "Font Awesome 6 Pro";
		content:"\f105"; 
		position:absolute;
		width:14px;
		height:14px;
		top:0;
		left:0;
	}
}
/* PRICE IN DROPDOWN - TABLES */
.uk-dropdown{
	.price-number {
		font-size: 1rem;
	}
}