.logo-slider{
	padding:0 40px;
	.slider-item{
		height: 125px;
		/*width: 200px;*/
		/*border:1px solid red;*/
		display: flex;
		justify-content: center;
		align-items: center;
		img,svg{
			max-width:165px;
			max-height:85px;
		}
	}
	.uk-position-center-right-out,
	.uk-position-center-left-out{
		color:$darkblue;
	}	
}