/*
1. sidebar
2. results
3. sidebar mobile
 */
/*
1. Sidebar
----------------------------------
*/
.sidebar-category{
	.uk-button{
		font-size:1.25rem;
		padding-right: 28px;
		&:hover,
		&:focus{
			padding-left:28px;
		}
		i:first-child{
			left:0;
		}
		i:first-child,
		i:last-child{
			top:10px;
		}
	}
	hr{
		margin-top: 2rem;
	}
	.uk-button.open,
	.uk-button.open:hover,
	.uk-button.open:focus,
	.uk-button.open:active
	{
		padding-left:28px;
		i:last-child{
			display:none;
		}
		i:first-child{
			display:block;
			transform:rotate(90deg);
			opacity:1;
			left:-14px;
		}
	}
}

/*
2. results
----------------------------------
*/
.search-results-faq{

}
.search-results-meta{
	line-height:40px;
	span{
		text-transform: uppercase;
		font-weight: bold;
		color:$mediumgrey;
		margin-right:1rem;
	}
}
.search-results-filter{
	display:flex;
	flex-direction: row;
	justify-content:space-between;
}
.num-results{
	font-weight: bold;
	color:$darkblue;
}
.select-label{
	font-weight: normal;
	color:$mediumgrey;
	margin-right:1rem;
}
.search-results-list{
	ul{
		margin:1.25rem 0;
	}
	li{
		padding:1.25rem 0;
		border-bottom:2px solid $lilladark;
	}
	li:first-of-type{
		border-top:2px solid $lilladark;
	}
	a.uk-display-block{
		margin-bottom:0.5rem;
	}
}
/*
3. sidebar mobile
*/
.cat-mobile .uk-button.uk-margin-remove-left.uk-button-default{
	margin-bottom:0.5rem;
}