/*
.sidebar-category-nav{
	background:transparent;
	@media screen and (max-width: $breakpoint-m){
		background:$lilladark;
		margin-left: 0;
		padding:1rem;
		border-radius:$border-radius-large;
	}
}
*/

/* TABS SIDEBAR FAQ */
div[class^='sidebar-'],
div[class*=' sidebar-']{
  .uk-tab{
  	
  	@media screen and (max-width: $breakpoint-m){
  		background:$lilladark;
  		margin-left: 0;
  		padding:1rem 0;
  		border-radius:$border-radius-large;
  	}
  }
  .uk-tab>*>a {
    /*
    padding: 1rem 10px;
    */
    padding: 0.5rem 1rem 0.5rem 1rem;
    @media screen and (min-width: $breakpoint-m){
    	padding: 0.5rem 10px 0.5rem 2rem;
  	}
    color: $darkgrey;
    font-size: 1.25rem;
    border-left: 2px solid transparent;
    text-transform: none;
    &:hover{
    	color:$darkblue;
    }  
  }
  .uk-tab>.uk-active>a {
      color: $darkblue;
      border-color: $darkblue;
      @media screen and (max-width: $breakpoint-m){
      	border-left: 2px solid transparent;
      }
  }
  .uk-tab-right::before {
      top: 0;
      bottom: 0;
      left: 0;
      right: auto;
      border-left: 2px solid $lilladark;
      
      @media screen and (max-width: $breakpoint-m){
      	border-bottom: 1px solid transparent
      }
  }
}