/****** Style Star Rating Widget *****/

.rating { 
  border: none;
  float: left;
}

.rating > input { 
  display: none; 
} 

.rating > label:before { 
  margin: 0 5px 0 0;
  font-size: 1rem;
  font-family: "Font Awesome 6 Pro";
  display: inline-block;
  content: "\f005";
  font-weight: 300;
}

.rating > .half:before { 
  content: "\f089";
  position: absolute;
  font-weight: 300;
}

.rating > label {
  color: $darkgrey;
  float: right; 
}

.rating-text{
  line-height:24px;
  color:$mediumgrey;
  padding:0 1.5rem;
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating > input:checked ~ label, /* show blue star when clicked */
.rating:not(:checked) > label:hover, /* hover current star */
.rating:not(:checked) > label:hover ~ label { 
  color: $darkblue; 
  cursor: pointer; 
  } /* hover previous stars in list */

.rating > input:checked + label:hover, /* hover current star when changing rating */
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label, /* lighten current selection */
.rating > input:checked ~ label:hover ~ label { 
  color: $darkblue; 
  cursor: pointer;
}


/* HOVER STARS */
.rating:not(:checked) > label:hover:before,
.rating:not(:checked) > label:hover ~ label:before { 
  color: $darkblue; 
  cursor: pointer;
  content: "\f005";
  font-weight: 900; 
} 


/* CHECKED FULL STARS */
.rating > input:checked ~ label:before, /* show gold star when clicked */
.rating:not(:checked) > label:hover, /* hover current star */
.rating:not(:checked) > label:hover ~ label {  
  content: "\f005";
  font-weight: 900;
} /* hover previous stars in list */ 
