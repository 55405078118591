/*
1. unsure if used lol - cards are styled in cards.scss
2.sidebar news article
 */
/*
1. unsure if used lol - cards are styled in cards.scss
 */
.blog-item{
	a{
		color:$darkgrey;
		display: block;
		position:relative;
		height:100%;
		width:100%;
		&:before{
			position:absolute;
			content:'';
			top:0;
			bottom:0;
			left:0;
			right:0;
			background:rgba($lightbg1,0);
			border-radius:$border-radius-large;
			transition:all 1s ease-in;
			border:1px solid transparent;
		}
		&:hover{
			text-decoration: none;
			&:before{
				position:absolute;
				content:'';
				top:-20px;
				bottom:-20px;
				left:-20px;
				right:-20px;
				background:rgba($lightbg1,0.5);
				border-radius:$border-radius-large;
				transition:all 0.5s ease-out;
				border:1px solid rgba($darkblue,0.5);
			}
			
		}
	}
	img{
		border-radius:$border-radius-large;
		background:$darkblue;
		position:relative;
	}
}
.blog-title{
	text-transform:uppercase;
	font-weight: bold;
	color:$darkblue;
	position:relative;
}
.blog-excerpt{
	display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  position:relative;
}
/*
2.sidebar news article
 */
.sidebar-news{
	.sidebar-title{
		font-size: 1.25rem;
		font-weight: bold;
		text-align:center;
		@media screen and (min-width: $breakpoint-m) {
			text-align:left;
		}
	}
	.news-article-small{
		margin-bottom: 2rem;
		@media screen and (min-width: $breakpoint-s) and (max-width: $breakpoint-m) {
			max-width: 50vw;
			margin:0 auto;
		}
		@media screen and (min-width: $breakpoint-l) {
			margin-bottom: 3.125rem;
		}
		.news-article-small--title{
			font-size:1.25rem;
			font-weight: bold;
			@media screen and (min-width: $breakpoint-l){
				font-size:1.5rem;
			}
			a{
				text-decoration: none;
				&:hover,
				&:focus{
					text-decoration: none;
				}
			}
		}
		img{
			border-radius:1.25rem;
		}
	}
}