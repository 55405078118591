
.speech-bubble {
	position: absolute;
	background: $light;
	border-top-right-radius:20px;
	border-top-left-radius:20px;
	border-bottom-left-radius:20px;
	width:100px;
	height:100px;
	filter: drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.4));
	display: flex;
	align-items: center;
  justify-content: center;
}

.speech-bubble:after {
	content: '';
	position: absolute;
	bottom: -70px;
	left: calc(100% - 40px);
	width: 0;
	height: 0;
	border: 40px solid transparent;
	border-top-color: $light;
	border-bottom: 0;
	border-right: 0;
	margin-left: 0px;
	margin-bottom: 40px;
}

.speech-bubble .icon{
	min-width:65px;
	height:65px;
}

/* POSITIONING ON ILLUSTRATIONS */
.speech-bubble.ssl{
	top: 2%;
	left: 23%;
}